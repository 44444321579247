import {useEffect} from 'react';
import {getUser, refreshAccessToken} from "./OAuth2Utils";
import {setCheckingAppCredentials, setUser} from "./Login";
import {connect} from "react-redux";


export const fetchToken = () => dispatch => {
    let accessToken = localStorage.getItem('access_token');
    let refreshToken = localStorage.getItem('refresh_token');
    if (accessToken && refreshToken) {
        dispatch(setCheckingAppCredentials(true));
        getUser()
            .then(data => {
                dispatch(setUser(data.data));
            })
            .catch(e => {
                refreshAccessToken()
                    .then(data => {
                        dispatch(fetchToken());
                    }).catch(e => {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        dispatch(setCheckingAppCredentials(false));
                    })
            });
    } else {
        dispatch(setCheckingAppCredentials(false));
        if (window.location.pathname === '/login') {
            dispatch(setUser(null));
        }
    }
};

export const OauthTokenReader = ({
    dispatch
}) => {
    useEffect(() => dispatch(fetchToken()));
    return null;
};

export default connect(
    state => ({
    }),
)(OauthTokenReader)