import React from 'react';
import {Box, Button, CircularProgress, Grid, Skeleton, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {getWeekFromDate} from "../../../Utils";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";

const SkeletonForm = () => {
    return (
        <>
            <Grid item xs={2} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Skeleton animation="wave" variant="circular" height={40} width={40} sx={{ position: 'relative' }}/>
            </Grid>
            <Grid item xs={8} sx={{
                mb: 1,
            }}>
                <Typography variant="h4" sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Skeleton animation="wave" height={50} width="20%" />
                </Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton animation="wave" height={60} width="100%"/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Skeleton animation="wave" height={60} width="100%"/>
            </Grid>
            <Grid item xs={4} sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Skeleton animation="wave" variant="circular" width={30} height={30} />
                <Skeleton animation="wave" height={30} width="50%"/>
            </Grid>
            <Grid item xs={4} sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Skeleton animation="wave" variant="circular" width={30} height={30} />
                <Skeleton animation="wave" height={30} width="50%"/>
            </Grid>
            <Grid item xs={4} sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Skeleton animation="wave" variant="circular" width={30} height={30} />
                <Skeleton animation="wave" height={30} width="50%"/>
            </Grid>
            <Grid item xs={12}>
                <Skeleton animation="wave" height={60} width="100%"/>
            </Grid>
            <Grid item xs={12}>
                <Skeleton animation="wave" height={60} width="100%"/>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained"
                        sx={{ float: 'right', mb: 2 }}>
                    <Skeleton animation="wave" height={30} width={120}/>
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth variant="contained" color="inherit" sx={{ mb:2 }}>
                    <Skeleton animation="wave" height={30} width="40%" />
                </Button>
                <Button fullWidth variant="contained" sx={{mb: 2, height: '50px'}}
                        color="primary">
                    <Skeleton animation="wave" height={60} width="20%" />
                </Button>
            </Grid>
        </>
    );
};

export default SkeletonForm;