import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: null,
    warehouses: null,
    warehousesList: null,
    warehousesDistance: null
}

const productSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        replaceProducts: (state, action) => {
            return {
                ...state,
                items:
                    action.payload
            }
        },
        replaceWarehouses: (state, action) => {
            return {
                ...state,
                warehouses: action.payload
            }
        },
        replaceWarehousesList: (state, action) => {
            return {
                ...state,
                warehousesList: action.payload
            }
        },
        replaceWarehousesDistance: (state, action) => {
            return {
                ...state,
                warehousesDistance: action.payload
            }
        },
        ereaseWarehouses: (state) => {
            return {
                ...state,
                warehouses: null
            }
        }
    }
})

export const {actions, reducer} = productSlice

export const {replaceProducts, replaceWarehouses, replaceWarehousesList, ereaseWarehouses, replaceWarehousesDistance} = actions