import React, {useState} from 'react';
import {CssBaseline, Typography, Box, Container, Alert, Button, Fab, Modal} from "@mui/material";
import SearchBar from "../../components/shop/SearchBar";
import AccordionList from "../../components/shop/Accordion/index";
import BottomNav from "../../components/shop/BottomNav";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {ereaseWarehouses} from "../../reducers/product/productSlice";
import {ereaseCart} from "../../reducers/cart/cartSlice";
import ShoppingSkeleton from "../../components/shop/ShoppingSkeleton";
import NavigationIcon from '@mui/icons-material/Navigation';
import HeaderShop from "../../components/shop/HeaderShop";
import Index from "../../components/shop/CallAndNav";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Shopping = ({items = null, dispatch, itemsInCart = null, warehousesCode = null, warehousesDistance = null,
                      cartWarehouseCode = null, cart = null, cartStatus = null, warehousesList = null, warehouseSearched = null}) => {

    const [searchResults, setSearchResults] = useState([])
    const [success, setSucces] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    // se rendre au panier
    const navigate = useNavigate()
    const navigateToCart = () => {
        navigate('/cart')
    }

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '2px solid #707070',
        boxShadow: 24,
        p: 4,
        borderRadius: '15px'
    }

    let arrayToShow = []
    if(itemsInCart != null) {
        let activeItem = []
        let notActiveItem = []
        items.map((item) => {
            if(item.warehouses.find(el => el.WarehouseCode === cartWarehouseCode)) {
                activeItem.push(item)
            } else {
                notActiveItem.push(item)
            }
        })
        arrayToShow = activeItem.concat(notActiveItem)
    }

    const scrollToTop = React.useCallback(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    const handleModal = () => {
        dispatch(ereaseCart())
        dispatch(ereaseWarehouses())
        setModalOpen(false)
        navigate('/magasin')
    }

    return ( 
        <Container component="main">
            <Index dispatch={dispatch} items={items} warehousesSearched={warehousesCode}/>
            <HeaderShop isAuth={true} cart={cart}/>
            <CssBaseline />
            <Modal open={modalOpen} onClose={() => {setModalOpen(false)}}>
                <Box sx={styleModal}>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', textAlign: 'center'}}>
                        <Typography variant="string">
                            Souhaitez vous retourner à la sélection des magasins ? Cela effacera votre panier actuel.
                        </Typography>
                        <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            <Button variant="outlined" color="primary" sx={{marginRight: '10px'}}
                                    onClick={() => setModalOpen(false)}
                            >
                                Non
                            </Button>
                            <Button variant="outlined" color="error" onClick={handleModal}>Oui</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Box
                sx={{
                    marginTop: {xs: 15, sm: 17, md: 18},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }} >
                {warehousesCode === null?
                    <ShoppingSkeleton />
                    :
                    <>
                        <Fab color="primary" sx={{position: 'fixed', right: '80px', bottom: '80px', display: {xs: 'none', sm: 'none', md: 'block'} }} size="small"
                            onClick={scrollToTop}>
                            <NavigationIcon />
                        </Fab>
                        <Box noValidate
                             sx={{ mt: 1, boxShadow: 5, p: 1, width: {xs: '95vw', sm: '95vw', md: '80%', lg: '75%', xl: '75%'},
                                 borderRadius: '10px', mb: 9,
                                 minHeight: '500px'}}>

                            <Box sx={{minHeight: 350, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                {success && <Alert severity="success">Envoie confirmé</Alert>}
                                <>
                                    <Button variant="outlined" onClick={() => {setModalOpen(true)}} sx={{fontSize: {xs: 13, md: 15} }}>
                                        <ArrowBackIcon sx={{ fontSize: 20, position: 'relative', left: {xs: '-18%', sm: '-25%', md: '-35%'} }} />
                                        Changer de magasin
                                    </Button>
                                    <SearchBar setSearchResults={setSearchResults} items={items} />
                                    <Box>
                                        {searchResults?.length ?
                                            searchResults.slice(0, 25).map((item, index) => {
                                                return(
                                                    <AccordionList item={item} index={index} key={index} warehousesCode={warehousesCode}
                                                                   itemsInCart={itemsInCart} cartWarehouseCode={cartWarehouseCode} cartStatus={cartStatus}
                                                                   warehousesList={warehousesList} warehousesDistance={warehousesDistance} cart={cart}
                                                                   warehouseSearched={warehouseSearched}
                                                    />
                                                )
                                            })
                                            :
                                            itemsInCart != null ?
                                                arrayToShow.slice(0, 25).map((item, index) => {
                                                    return(
                                                        <AccordionList item={item} index={index} key={index} warehousesCode={warehousesCode}
                                                                       itemsInCart={itemsInCart} cartWarehouseCode={cartWarehouseCode} cartStatus={cartStatus}
                                                                       warehousesList={warehousesList} warehousesDistance={warehousesDistance} cart={cart}
                                                                       warehouseSearched={warehouseSearched}
                                                        />
                                                    )
                                                })
                                                :
                                                items.slice(0, 25).map((item, index) => {
                                                    return(
                                                        <AccordionList item={item} index={index} key={index} warehousesCode={warehousesCode}
                                                                       itemsInCart={itemsInCart} cartWarehouseCode={cartWarehouseCode} cartStatus={cartStatus}
                                                                       warehousesList={warehousesList} warehousesDistance={warehousesDistance} cart={cart}
                                                                       warehouseSearched={warehouseSearched}
                                                        />
                                                    )
                                                })
                                        }
                                    </Box>
                                </>
                            </Box>
                        </Box>
                    </>
                }
            </Box>
            <BottomNav />
        </Container>
    );
};

export default connect(
    state => ({
        items : state.productsReducer.items,
        itemsInCart: state.cartReducer.itemsInCart,
        cartWarehouseCode: state.cartReducer.warehouseCode,
        cart: state.cartReducer,
        cartStatus: state.cartReducer.status,
        warehousesCode: state.productsReducer.warehouses,
        warehousesList: state.productsReducer.warehousesList,
        warehousesDistance: state.productsReducer.warehousesDistance,
        warehouseSearched: state.productsReducer.warehouses
    })
)(Shopping)