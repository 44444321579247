import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Skeleton, TextField, Typography, CircularProgress} from "@mui/material";
import BottomNav from "../../components/shop/BottomNav";
import {useNavigate, useParams} from "react-router-dom"
import {apiGet, apiPost} from "../../authentication/OAuth2Utils";
import {connect} from "react-redux";
import {
    decreaseItemQuantity,
    increaseItemQuantity,
    setId,
    setItemQuantity, setWarehouseEmail,
    setWarehouseSearch
} from "../../reducers/cart/cartSlice";
import {replaceProducts} from "../../reducers/product/productSlice";
import HeaderShop from "../../components/shop/HeaderShop";
import Index from "../../components/shop/CallAndNav";
import {clone} from "ramda";
import formatNumber from "../../Utils";
import EditCartItemSkeleton from "../../components/shop/editCartItemSkeleton";

const EditCartItem = ({dispatch, items = null, itemsInCart = null, warehousesSearched = null,
                          cartStatus = null, warehousesList = null, cart = null}) => {

    const { itemId } = useParams()
    const { magasinId } = useParams()
    const [itemToEdit, setItemToEdit] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    const [currWarehouse, setCurrWarehouse] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const [inputValue, setInputValue] = useState(0)

    const navigate = useNavigate()

    // appelez l'api get items si les items sont vides, sinon définir l'item a éditer
    useEffect(() => {
        if(items === null) {
            apiGet('/api/get-items').then(data => {
                if(data.valid === true) {
                    dispatch(replaceProducts(data.liste_article))
                    setIsLoaded(true)
                } else {
                    console.error("erreur")
                }
            })
        } else {
            let currItem = items.find(el => el.ItemCode === itemId)
            setItemToEdit({
                ItemCode: itemId,
                WarehouseCode: magasinId,
                ItemName: currItem.ItemName,
                itemQuantity: 0,
                InStock: currItem.warehouses.find(e => e.WarehouseCode === magasinId).InStock,
                SalesUnit: currItem.SalesPackagingUnit
            })
            setCurrWarehouse(warehousesList.find(e => e.WarehouseCode === magasinId))
            setIsLoaded(true)
            if(itemsInCart !== null && itemsInCart[itemId] !== undefined) {
                setInputValue(itemsInCart[itemId].itemQuantity)
            }
        }
    }, [])

    const postCloned = (clonedCart) => {
        apiPost('/api/savecart', clonedCart).then(data => {
            if(data.status === 'valid'){
                dispatch(setId(data.cart))
                setIsSaving(false)
            }
        })
    }

    const handleSavingCart = (param) => {
        if(cartStatus !== 'valid') {
            setIsSaving(true)
            let clonedCart = clone(cart)
            if(param === 'add') {
                if(itemsInCart != null && itemsInCart[itemToEdit.ItemCode] != null) {
                    if(itemsInCart[itemToEdit.ItemCode].itemQuantity < itemToEdit.InStock) {
                        clonedCart.itemsInCart[itemToEdit.ItemCode].itemQuantity += 1
                        dispatch(increaseItemQuantity(itemToEdit))
                        postCloned(clonedCart)
                    }
                } else if (itemsInCart === null) {
                    let obj = {}
                    obj[itemToEdit.ItemCode] = {
                        itemCode: itemToEdit.ItemCode,
                        itemQuantity: 1,
                        itemName: itemToEdit.ItemName,
                        salesUnit: itemToEdit.SalesUnit
                    }
                    clonedCart.itemsInCart = obj
                    clonedCart.status = 'active'
                    clonedCart.warehouseCode = itemToEdit.WarehouseCode
                    clonedCart.warehouseSearch = warehousesSearched
                    clonedCart.warehouseEmail = currWarehouse.U_W3C_WMEL
                    dispatch(setWarehouseEmail(currWarehouse.U_W3C_WMEL))
                    dispatch(increaseItemQuantity(itemToEdit))
                    dispatch(setWarehouseSearch(warehousesSearched))
                    postCloned(clonedCart)
                } else {
                    clonedCart[itemToEdit.ItemCode] = {
                        itemCode: itemToEdit.ItemCode,
                        itemQuantity: 1,
                        itemName: itemToEdit.ItemName,
                        salesUnit: itemToEdit.SalesUnit
                    }
                    dispatch(increaseItemQuantity(itemToEdit))
                    postCloned(clonedCart)
                }
            } else if (param === 'rmv') {
                if(itemsInCart !== null) {
                    if(clonedCart.itemsInCart[itemToEdit.ItemCode]) {
                        if(clonedCart.itemsInCart[itemToEdit.ItemCode].itemQuantity > 1) {
                            clonedCart.itemsInCart[itemToEdit.ItemCode].itemQuantity -= 1
                            postCloned(clonedCart)
                            dispatch(decreaseItemQuantity(itemsInCart[itemToEdit.ItemCode].itemCode))
                        } else if (clonedCart.itemsInCart[itemToEdit.ItemCode].itemQuantity === 1) {
                            delete clonedCart.itemsInCart[itemToEdit.ItemCode]
                            postCloned(clonedCart)
                            dispatch(decreaseItemQuantity(itemsInCart[itemToEdit.ItemCode].itemCode))
                        }
                    } else {
                        setIsSaving(false)
                    }
                } else {
                    setIsSaving(false)
                }
            }
        }
    }

    const handleInputRegex = (e) => {
        const regex = /^[0-9\b]+$/
        if (e.target.value === "" || regex.test(e.target.value)) {
            if(e.target.value === "") {
                setInputValue(e.target.value)
            } else {
                setInputValue(parseInt(e.target.value, 10))
            }
        }
    }

    const handeInputValueChange = () => {
        setIsSaving(true)
        let newObj = {
            ItemCode: itemToEdit.ItemCode,
            itemQuantity: inputValue,
            ItemName: itemToEdit.ItemName,
            SalesUnit: itemToEdit.SalesUnit
        }
        let clonedCart = clone(cart)
        if(inputValue > 0 && inputValue < itemToEdit.InStock) {
            if(clonedCart.itemsInCart !== null) {
                if(clonedCart.itemsInCart[itemToEdit.ItemCode]) {
                    // la on change juste la quantité puis on post
                    clonedCart.itemsInCart[itemToEdit.ItemCode].itemQuantity = inputValue
                    postCloned(clonedCart)
                    dispatch(setItemQuantity(newObj))
                } else {
                    // la on ajoute l'objet en entier
                    clonedCart.itemsInCart[itemToEdit.ItemCode] = newObj
                    //post
                    postCloned(clonedCart)
                    dispatch(setItemQuantity(newObj))
                }
            } else {
                let newObjAndWarehouse = {
                    ItemCode: itemToEdit.ItemCode,
                    itemQuantity: inputValue,
                    ItemName: itemToEdit.ItemName,
                    SalesUnit: itemToEdit.SalesUnit,
                    WarehouseCode: magasinId
                }
                // la on crée le panier avec les autres setters aussi genre status warehoussearched etc
                clonedCart.itemsInCart = newObj
                clonedCart.warehouseCode = magasinId
                clonedCart.warehouseSearch = warehousesSearched
                clonedCart.status = 'active'

                //post
                postCloned(clonedCart)
                dispatch(setItemQuantity(newObjAndWarehouse))
                dispatch(setWarehouseSearch(warehousesSearched))
            }
        } else if (inputValue === 0 && clonedCart.itemsInCart[itemToEdit.ItemCode]) {
            delete clonedCart.itemsInCart[itemToEdit.ItemCode]
            dispatch(setItemQuantity(newObj))
            postCloned(clonedCart)
        } else if (inputValue > itemToEdit.InStock) {
            setInputValue(itemToEdit.InStock)
            setIsSaving(false)
        }
    }


    return (
        <Container component="main">
            <HeaderShop isAuth={true} cart={cart}/>
            <Index dispatch={dispatch} items={items} warehousesSearched={warehousesSearched}/>
            <Box sx={{marginTop: 18, display: 'flex',
                flexDirection: 'column', alignItems: 'center'}} >
                <Box noValidate
                     sx={{ mt: 1, boxShadow: 5, p: 1, width: {xs: '100%', sm: '100%', md: '80%', lg: '75%', xl: '75%'},
                         borderRadius: '10px', mb: 3, minHeight: '300px'}}>
                    {isLoaded ?
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant="h5" sx={{margin: 0, textAlign: 'center'}}>{itemToEdit.name}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{minHeight: 200, display: 'flex', flexDirection: 'column', marginBottom: '30px'}}>
                                <Typography variant="h6" sx={{textAlign: 'center', marginBottom: '20px', marginTop: '15px'}}>{itemToEdit.ItemName}</Typography>
                                <Box sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: {xs: 'center'},
                                    marginBottom: '25px', flexDirection: {xs: 'column', sm: 'row'}}}>
                                    <Typography variant="string">Stock disponible : {formatNumber(itemToEdit.InStock)}{itemToEdit.SalesPackagingUnit != null ? ' ' + itemToEdit.SalesPackagingUnit : ' unités'}</Typography>
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'center', mb: 1}}>
                                    <Typography variant="string">Quantité souhaitée :</Typography>
                                </Box>
                                <Box sx={{display: 'flex', alignItems: 'center', marginRight: 'auto', marginLeft: 'auto', marginTop: '10px'}}>
                                    {isSaving ?
                                        <>
                                            <Button variant="outlined" color="error" onClick={() => {}}>
                                                <Skeleton variant="rectangular" width={34} height={25} />
                                            </Button>
                                            <Box sx={{paddingRight: '20px', paddingLeft: '20px'}}>
                                                <TextField hiddenLabel variant="standard" size="small" sx={{ width: '50px'}}
                                                           inputProps={{ style: {textAlign: 'center'} }} value={inputValue}
                                                />
                                            </Box>
                                            <Button variant="outlined" color="success" onClick={() => {}}>
                                                <Skeleton variant="rectangular" width={34} height={25} />
                                            </Button>
                                        </>
                                    :
                                        <>
                                            <Button variant="outlined" color="error" onClick={() => {
                                                handleSavingCart('rmv')
                                                if(inputValue > 0) {
                                                    let value = inputValue - 1
                                                    setInputValue(value)
                                                }
                                            }}>
                                                -
                                            </Button>
                                            <Box sx={{paddingRight: '20px', paddingLeft: '20px'}}>
                                                <TextField hiddenLabel variant="standard" size="small" sx={{ width: '45px'}}
                                                    inputProps={{ style: {textAlign: 'center'}, inputMode: 'numeric', pattern: '[0-9]*' }} value={inputValue}  onChange={(e) => {handleInputRegex(e)}}
                                                    onBlur={() => {handeInputValueChange()}}
                                                />
                                            </Box>
                                            <Button variant="outlined" color="success" onClick={() => {
                                                if(inputValue < itemToEdit.InStock) {
                                                    handleSavingCart('add')
                                                    let value = inputValue + 1
                                                    setInputValue(value)
                                                }
                                            }}>
                                                +
                                            </Button>
                                        </>
                                    }
                                </Box>
                            </Box>
                            {isSaving ?
                                <Button variant="outlined" fullWidth onClick={() => {}}>
                                    <CircularProgress size={20} />
                                </Button>
                             :
                                <Button variant="outlined" fullWidth onClick={() => {
                                    navigate('/shopping')}}>Revenir à la liste des articles
                                </Button>
                            }
                        </>
                    :
                        <EditCartItemSkeleton />
                    }

                </Box>
            </Box>
            <BottomNav />
        </Container>
    );
};

export default connect(
    state => ({
        items : state.productsReducer.items,
        itemsInCart: state.cartReducer.itemsInCart,
        cartStatus: state.cartReducer.status,
        cartWarehouseCode: state.cartReducer.warehouseCode,
        cart: state.cartReducer,
        warehousesSearched: state.productsReducer.warehouses,
        warehousesList: state.productsReducer.warehousesList
    })
)(EditCartItem)