import React from 'react';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Grid, Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import {assoc} from "ramda";

const SelectTpsTravail = ({timeSheet, setTimeSheet}) => {

    const tps = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12]

    const handleChange = (e) => {
       setTimeSheet(assoc('hours', e.target.value, timeSheet))
    }

    return (
        <Grid item xs={12}>
            <FormControl sx={{ minWidth: {
                    xs: 120,
                    sm: 170,
                    md: 220,
                    lg: 250,
                    xl: 250
                }, marginBottom: 2 }} fullWidth={true} spacing={3}>
                <InputLabel id="simple-select-tps-travail-label">Heures de travail</InputLabel>
                <Select
                    labelId="simple-select-tps-travail-label"
                    id="simple-select-tps-travail"
                    onChange={handleChange}
                    fullWidth={true}
                    value={timeSheet.hours}
                    label="Temps de travail"
                >
                    { tps.map((tps, index) => {
                        return (
                            <MenuItem value={tps} key={index}>{tps}</MenuItem>
                        )
                    })
                    }
                </Select>
            </FormControl>
        </Grid>
    );
};

export default SelectTpsTravail;