import React, {useState} from 'react';
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {Stack, TextField} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useDispatch, useSelector} from "react-redux";
import {assoc} from "ramda";

const DatePickerForm = ({timeSheet, setTimeSheet}) => {

    const handleChange = (e) => {
        let date = `${e.$y}-${e.$M +1}-${e.$D}`
        setTimeSheet(assoc('date', date, timeSheet))
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DesktopDatePicker
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="DD/MM/YYYY"
                    label="Jour"
                    value={timeSheet.date}
                    />
            </Stack>
        </LocalizationProvider>
    );
};

export default DatePickerForm;