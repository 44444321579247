import {createSlice} from "@reduxjs/toolkit";
import {todayHTMLFormat} from "../../Utils";
import {remove, update} from "ramda";

const formSlice = createSlice({
    name: "form",
    initialState: {
        site: '',
        date: todayHTMLFormat(),
        mode: 1,
        zone: 1,
        fwdTrip: 0,
        rtnTrip: 0,
        hours: 8.5,
        companions: []
    },
    reducers: {
        addSite: (state, action) => {
            return {
            ...state,
                    site:
                action.payload
            }},
        addDate: (state, action) => ({
            ...state,
                date: action.payload
        }),
        addMode: (state, action) => ({
            ...state,
                mode: action.payload
        }),
        addZone: (state, action) => ({
            ...state,
                zone: action.payload
        }),
        addFwdtrip: (state, action) => ({
            ...state,
                fwdTrip: action.payload
        }),
        addRtntrip: (state, action) => ({
            ...state,
                rtnTrip: action.payload
        }),
        addHours: (state, action) => ({
            ...state,
                hours: action.payload
        }),
        addCompanions: (state, action) => ({
            ...state,
                companions: [...state.companions, action.payload]
        }),
        deleteCompanion: (state, action) => ({
            ...state,
                companions: remove(action.payload, 1, state.companions)
        }),
        addCompName: (state, {payload: {id, index}}) => ({
            ...state,
                companions: update(index, {
                    ...state.companions[index],
                    id: id
                }, state.companions)
        }),
        addCompHours: (state, {payload: {hours, index}}) => ({
            ...state,
                companions: update(index, {
                    ...state.companions[index],
                    hours: hours
                }, state.companions)
        }),
        addCompMode: (state, {payload: {mode, index}}) => ({
            ...state,
            companions: update(index, {
                ...state.companions[index],
                mode: mode
            }, state.companions)
        }),
        addFwdTripComp: (state, {payload: {fwdTrip, index}}) => ({
            ...state,
            companions: update(index, {
                ...state.companions[index],
                fwdTrip: fwdTrip
            }, state.companions)
        }),
        addRtnTripComp: (state, {payload: {rtnTrip, index}}) => ({
            ...state,
            companions: update(index, {
                ...state.companions[index],
                rtnTrip: rtnTrip
            }, state.companions)
        }),
        addZoneComp: (state, {payload: {zone, index}}) => ({
            ...state,
            companions: update(index, {
                ...state.companions[index],
                zone: zone
            }, state.companions)
        }),
        addCommentChoiceComp: (state, {payload: {commentChoice, index}}) => ({
            ...state,
            companions: update(index, {
                ...state.companions[index],
                commentChoice: commentChoice
            }, state.companions)
        }),
        addCommentComp: (state, {payload: {comment, index}}) => ({
            ...state,
            companions: update(index, {
                ...state.companions[index],
                comment: comment
            }, state.companions)
        })
    }
})

export const {actions, reducer} = formSlice;