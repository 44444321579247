import React from 'react';
import {Box, Button, Skeleton, TextField} from "@mui/material";

const ShoppingCartSkeleton = () => {
    return (
        <>
            <Box sx={{minHeight: 250, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', padding: 2, border: 1, mb: 1, flexDirection: {xs: 'column', sm: 'row'},
                    borderColor: 'grey.500', borderRadius: 1, minWidth: {xs: 250, sm: 350, md: 550, lg: 650, xl: 650}}} >
                    <Box sx={{maxWidth: '350px', textOverflow: 'ellipsis', whiteSpace: {xs: 'normal', md: 'nowrap'}, overflow: 'hidden',
                        width: {xs:'100%', sm: '200px', md: '300px', lg: '349px'}, mb: {xs: 1, sm: 0},
                        fontSize: {xs: 14, sm: 15, md: 16, lg: 16}, display: 'flex', justifyContent: {xs: 'center', sm: 'flex-start'} }}>
                        <Skeleton width={150} height={30} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button variant="outlined" color="error" onClick={() => {}} sx={{mr: 1}}>
                            <Skeleton variant="rectangular" width={32} height={25} />
                        </Button>
                        <Box>
                            <Skeleton width={50} height={30} />
                        </Box>
                        <Button variant="outlined" color="success" onClick={() => {}} sx={{ml: 1}}>
                            <Skeleton variant="rectangular" width={32} height={25} />
                        </Button>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between', padding: 2, border: 1, mb: 1, flexDirection: {xs: 'column', sm: 'row'},
                    borderColor: 'grey.500', borderRadius: 1, minWidth: {xs: 250, sm: 350, md: 550, lg: 650, xl: 650}}} >
                    <Box sx={{maxWidth: '350px', textOverflow: 'ellipsis', whiteSpace: {xs: 'normal', md: 'nowrap'}, overflow: 'hidden',
                        width: {xs:'100%', sm: '200px', md: '300px', lg: '349px'}, mb: {xs: 1, sm: 0},
                        fontSize: {xs: 14, sm: 15, md: 16, lg: 16}, display: 'flex', justifyContent: {xs: 'center', sm: 'flex-start'} }}>
                        <Skeleton width={150} height={30} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button variant="outlined" color="error" onClick={() => {}} sx={{mr: 1}}>
                            <Skeleton variant="rectangular" width={32} height={25} />
                        </Button>
                        <Box>
                            <Skeleton width={50} height={30} />
                        </Box>
                        <Button variant="outlined" color="success" onClick={() => {}} sx={{ml: 1}}>
                            <Skeleton variant="rectangular" width={32} height={25} />
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Button fullWidth variant="contained"><Skeleton width={300}/></Button>
            <Button fullWidth variant="outlined" sx={{marginTop: '10px'}} color="error"><Skeleton width={300}/></Button>
            <Button fullWidth variant="contained" sx={{ mb: 2, mt: 2 }} color="inherit"><Skeleton width={300}/></Button>
        </>
    );
};

export default ShoppingCartSkeleton;