import React from 'react';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import {assoc} from "ramda";

const SelectCompName = ({index=0, companions, setTimeSheet, timeSheet}) => {

    const handleChange = (e) => {
        let timeSheetCopy = timeSheet
        timeSheetCopy.companions[index].id = e.target.value
        setTimeSheet({...timeSheetCopy})
    }
    return (
            <div>
                <FormControl sx={{ minWidth: {
                        xs: 120,
                        sm: 170,
                        md: 220,
                        lg: 250,
                        xl: 250
                    }, marginBottom: 2 }} fullWidth={true}>
                    <InputLabel id="simple-select-comp-label">Nom</InputLabel>
                    <Select
                        labelId="simple-select-comp-label"
                        id="simple-select-comp"
                        onChange={(e) => {
                            handleChange(e)
                        }}
                        autoWidth
                        value={timeSheet.companions[index].id}
                        label="Nom"
                    >
                        {companions.map((acomp, index) => {
                            return (
                                <MenuItem value={acomp.id} key={index}>{acomp.name}</MenuItem>
                            )
                        })
                        }
                    </Select>
                </FormControl>
            </div>
    );
};

export default SelectCompName;