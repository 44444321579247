import React from 'react';
import {Grid, Select} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import {useDispatch, useSelector} from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {assoc} from "ramda";

const SelectTpsTrajet = ({setTimeSheet, timeSheet}) => {

    const tps = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12]

    const handleChangeAl = (e) => {
        setTimeSheet(assoc('fwdTrip', e.target.value, timeSheet))
    }
    const handleChangeRt = (e) => {
        setTimeSheet(assoc('rtnTrip', e.target.value, timeSheet))
    }

    return (
        <>
            <Grid item xs={6}>
                <FormControl sx={{ minWidth: {
                        xs: 150,
                        sm: 200,
                        md: 250,
                        lg: 250,
                        xl: 250
                    }, marginBottom: 2 }} fullWidth={true}>
                    <InputLabel id="simple-select-tps-alle-label">Temps de trajet aller</InputLabel>
                    <Select
                        labelId="simple-select-tps-alle-label"
                        id="simple-select-tps-alle"
                        onChange={handleChangeAl}
                        autoWidth
                        value={timeSheet.fwdTrip}
                        label="Temps de trajet aller"
                    >
                        { tps.map((tps, index) => {
                            return (
                                <MenuItem value={tps} key={index}>{tps}</MenuItem>
                            )
                        })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl sx={{ minWidth: {
                        xs: 150,
                        sm: 200,
                        md: 250,
                        lg: 250,
                        xl: 250
                    }, marginBottom: 2 }} fullWidth={true}>
                    <InputLabel id="simple-select-tps-retour-label">Temps de trajet retour</InputLabel>
                    <Select
                        labelId="simple-select-tps-retour-label"
                        id="simple-select-tps-retour"
                        onChange={handleChangeRt}
                        autoWidth
                        value={timeSheet.rtnTrip}
                        label="Temps de trajet retour"
                    >
                        { tps.map((tps, index) => {
                            return (
                                <MenuItem value={tps} key={index}>{tps}</MenuItem>
                            )
                        })
                        }
                    </Select>
                </FormControl>
            </Grid>
        </>
    );
};

export default SelectTpsTrajet;