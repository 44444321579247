import React from 'react';
import FormControl from "@mui/material/FormControl";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";

const RadioGroupComp = ({index=0, timeSheet, setTimeSheet}) => {

    const handleChange = (e) => {
        let timeSheetCopy = timeSheet
        timeSheetCopy.companions[index].mode = parseInt(e.target.value)
        setTimeSheet({...timeSheetCopy})
    }

    return (
        <div>
            <FormControl spacing={3} fullWidth={true}>
                <RadioGroup
                    row
                    aria-labelledby="row-radio-buttons-group-comp-label"
                    name="row-radio-buttons-group-comp"
                    value={timeSheet.companions[index].mode}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <FormControlLabel control={<Radio />} value={1} label="local" onChange={(e) => {
                        handleChange(e)
                    }} />
                    <FormControlLabel control={<Radio />} value={3} label="GDSN" onChange={(e) => {
                        handleChange(e)
                    }} />
                    <FormControlLabel control={<Radio />} value={2} label="Grand déplacement" onChange={(e) => {
                        handleChange(e)
                    }} />
                </RadioGroup>
            </FormControl>
        </div>
    );
};

export default RadioGroupComp;