import React, {useEffect, useState} from 'react';
import {Badge, BottomNavigation, BottomNavigationAction, Box} from "@mui/material";
import StoreIcon from '@mui/icons-material/Store';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {Link, useNavigate} from "react-router-dom";
import {connect} from "react-redux";

const BottomNav = ({cartStatus = null, itemsInCart = null}) => {

    const navigate = useNavigate()
    const navigateToCart = () => {
        navigate('/cart')
    }
    const navigateHome = () => {
        navigate('/shopping')
    }
    const navigateConfirm = () => {
        navigate('/confirm')
    }

    return (
        <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation showLabels sx={{ backgroundColor: '#1565c0', pt: {xs: 0, sm: 1}}}>
                <BottomNavigationAction label="Stock" icon={<StoreIcon sx={{width: {xs: 20, sm: 30}}} />}
                                        sx={{color: 'white'}} onClick={navigateHome}/>
                {cartStatus != null ?
                    <BottomNavigationAction label="Panier" icon={
                        <Badge badgeContent={Object.keys(itemsInCart).length} color="warning"><LocalGroceryStoreIcon sx={{width: {xs: 20, sm: 30}}}/></Badge>}
                                            sx={{color: 'white'}} onClick={navigateToCart}/>
                :
                    <BottomNavigationAction label="Panier" icon={<LocalGroceryStoreIcon sx={{width: {xs: 20, sm: 30}}}/>}/>
                }
                {cartStatus === 'valid' ?
                    <BottomNavigationAction label="Confirmation"  sx={{color: 'white'}} icon={<AccountBalanceIcon sx={{width: {xs: 20, sm: 30}}} />}
                        onClick={navigateConfirm}/>
                :
                    <BottomNavigationAction label="Confirmation" icon={<AccountBalanceIcon sx={{width: {xs: 20, sm: 30}}} />} />
                }
            </BottomNavigation>
        </Box>
    );
};

export default connect(
    state => ({
        cartStatus: state.cartReducer.status,
        itemsInCart: state.cartReducer.itemsInCart
    })
)(BottomNav)