import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {ThemeProvider} from "@mui/styles";
import * as R from 'ramda';
import {
    Container,
    createTheme,
    CssBaseline,
    Typography,
    Box,
    Select,
    Button,
    Grid,
    CircularProgress, Alert
} from "@mui/material";
import SelectChantier from "../../components/timeSheet/SelectChantier";
import DatePickerForm from "../../components/timeSheet/DatePickerForm";
import RadioGroupForm from "../../components/timeSheet/RadioGroupForm";
import SelectZone from "../../components/timeSheet/SelectZone";
import SelectTpsTrajet from "../../components/timeSheet/SelectTpsTrajet";
import SelectTpsTravail from "../../components/timeSheet/SelectTpsTravail";
import SelectCompName from "../../components/timeSheet/SelectCompName";
import SelectCompTravail from "../../components/timeSheet/SelectCompTravail";
import RadioGroupComp from "../../components/timeSheet/RadioGroupComp";
import TpsTrajetComp from "../../components/timeSheet/TpsTrajetComp";
import SelectZoneComp from "../../components/timeSheet/SelectZoneComp";
import CommentChoice from "../../components/timeSheet/CommentChoice";
import Comment from "../../components/timeSheet/Comment";
import {apiGet} from "../../authentication/OAuth2Utils";
import SkeletonForm from "../../components/timeSheet/SkeletonForm";
import {formErrors, getWeekFromDate, saveData, saveEdit, todayHTMLFormat} from "../../Utils";
import {Link, useParams} from "react-router-dom";
import {assoc} from "ramda";
import Header from "../../components/timeSheet/Header";
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Home = () => {

    const theme = createTheme()
    const [errors, setErrors] = useState([])
    const [success, setSuccess] = useState(false)
    const [isSubmited, setIsSubmited] = useState(false)
    const [site, setSite] = useState(null)
    const [companions, setCompanions] = useState(null)
    const [timeSheet, setTimeSheet] = useState({
        site: '',
        date: todayHTMLFormat(),
        mode: 1,
        zone: 1,
        fwdTrip: 0,
        rtnTrip: 0,
        hours: 8,
        companions: []
    })
    let companion ={
        id: '',
        hours: timeSheet.hours,
        mode: timeSheet.mode,
        zone: timeSheet.zone,
        fwdTrip: timeSheet.fwdTrip,
        rtnTrip: timeSheet.rtnTrip,
        site: timeSheet.site,
        date: timeSheet.date,
        commentChoice: '',
        comment: '',
        companions: []
    }

    let editVerif = useParams()

    useEffect(() => {
        if(editVerif.hasOwnProperty('order')) {
            let date = editVerif.date
            let order = editVerif.order
            apiGet('/api/capture/info/' + date + '/' + order).then(data => {
                if(data.response.hasOwnProperty('companions') && data.response.hasOwnProperty('orders')) {
                    setCompanions(data.response.companions)
                    setTimeSheet(data.response.form)
                    setSite(data.response.orders)

                } else {
                    console.log('erreur lors de lattribution des données')
                }
            })
        } else {
            apiGet('/api/capture/info/').then(data => {
                if(data.response.hasOwnProperty('companions') && data.response.hasOwnProperty('orders')) {
                    setCompanions(data.response.companions)
                    setSite(data.response.orders)

                } else {
                    console.log('erreur lors de lattribution des données')
                }
            })
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsSubmited(true)
        if(formErrors(timeSheet).length === 0) {
            saveData(JSON.stringify(timeSheet)).then(response => {
                if (response.status === 'success') {
                    setErrors([])
                    setSuccess(true)
                    window.scroll(0,0)
                    setIsSubmited(false)
                }
            })
        } else {
            setSuccess(false)
            console.log(formErrors(timeSheet))
            setErrors(formErrors(timeSheet))
            window.scroll(0,0)
            setIsSubmited(false)
        }
    }

    const handleEdit = (e) => {
        e.preventDefault()
        setIsSubmited(true)
        if(formErrors(timeSheet).length === 0) {
            saveEdit(JSON.stringify(timeSheet)).then(response => {
                if(response.status === 'success') {
                    setErrors([])
                    setSuccess(true)
                    window.scroll(0,0)
                    setIsSubmited(false)
                }
            })
        } else {
            setSuccess(false)
            setErrors(formErrors(timeSheet))
            window.scroll(0,0)
            setIsSubmited(false)
        }
    }

    const addComp = () => {
        let timeSheetCopy = timeSheet
        timeSheetCopy.companions.push(companion)
        setTimeSheet({...timeSheetCopy})
    }

    const deleteComp = (index) => {
        let timeSheetCopy = timeSheet
        timeSheetCopy.companions.splice(index, 1)
        setTimeSheet({...timeSheetCopy})
    }

    const applyData = () => {
        let timeSheetCopy = timeSheet
        timeSheetCopy.companions.map((comp)=> {
            comp.hours = timeSheetCopy.hours
            comp.mode = timeSheetCopy.mode
            comp.zone = timeSheetCopy.zone
            comp.fwdTrip = timeSheetCopy.fwdTrip
            comp.rtnTrip = timeSheetCopy.rtnTrip
            comp.site = timeSheetCopy.site
            comp.date = timeSheetCopy.date
        })
        setTimeSheet({...timeSheetCopy})
    }

    let d = new Date()

    return (
        <ThemeProvider theme={theme}>
            <Header isAuth={true}/>
            <Container component="main" sx={{
                mt: {xs: 14, sm: 16, md: 19},
            }}>
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }} >
                    <Box component="form" onSubmit={handleSubmit} noValidate
                         sx={{ mt: 1, boxShadow: 5,
                             p: {
                             xs: 1,
                             sm: 2,
                             md: 3,
                             lg: 5,
                             xl: 5
                             },
                             width: {
                                md: '100%',
                                lg: '75%'
                             },
                             borderRadius: '10px', mb: 3, minHeight: '500px' }}>
                        <Grid container spacing={2}>
                            {site === null ?
                                <SkeletonForm />
                                :
                                <>
                                    <Grid item xs={2} sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <Link to={'/list/' + d.getFullYear() + '/' + getWeekFromDate(d) }>
                                            <ArrowCircleLeftRoundedIcon color="primary"
                                                                        fontSize="large"
                                                                        sx={{ position: 'relative' }}/>
                                        </Link>
                                    </Grid>
                                    {editVerif.hasOwnProperty('order') ?
                                        <Grid item xs={8} sx={{
                                            mb: 1,
                                        }}>
                                            <Typography variant="h4" sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                fontSize: {xs: 24, sm: 26, md: 28}
                                            }}>
                                                Modification
                                            </Typography>
                                        </Grid>
                                        :
                                        <Grid item xs={8} sx={{
                                            mb: 1,
                                        }}>
                                            <Typography variant="h4" sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontSize: {xs: 24, sm: 26, md: 28}
                                            }}>
                                                Saisie
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={2}>

                                    </Grid>
                                        { success ?
                                            editVerif.hasOwnProperty('order') ?
                                            <Grid item xs={12} sx={{ mb: 2 }}>
                                                <Alert severity="success">Modification réussie !</Alert>
                                            </Grid>
                                            :
                                        <Grid item xs={12} sx={{ mb: 2 }}>
                                            <Alert severity="success" >Saisie réussie !</Alert>
                                        </Grid>
                                            :
                                            null
                                    }
                                    { errors.length > 0 &&
                                        <Grid item xs={12} sx={{ mb: 2 }}>
                                        <Alert severity="error">Erreur pour le ou les champs : {errors} </Alert>
                                        </Grid>
                                    }
                                        <Grid item xs={12} md={6}>
                                            <SelectChantier site={site} timeSheet={timeSheet}
                                                            setTimeSheet={setTimeSheet} editVerif={editVerif}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <DatePickerForm timeSheet={timeSheet} setTimeSheet={setTimeSheet}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RadioGroupForm timeSheet={timeSheet} setTimeSheet={setTimeSheet}/>
                                        </Grid>
                                    {timeSheet.mode == 2 || timeSheet.mode == 3 ?
                                        <SelectTpsTrajet timeSheet={timeSheet} setTimeSheet={setTimeSheet}/>
                                        :
                                        <Grid item xs={12}>
                                        <SelectZone timeSheet={timeSheet} setTimeSheet={setTimeSheet} />
                                        </Grid>
                                    }
                                    {timeSheet.companions.length > 0 ?
                                        <>
                                            <Grid item xs={6}>
                                                <SelectTpsTravail timeSheet={timeSheet} setTimeSheet={setTimeSheet} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth variant="contained" color="inherit"
                                                        onClick={applyData} sx={{ height: '53px'}}
                                                >
                                                    <ArrowDownwardIcon />
                                                </Button>
                                            </Grid>
                                        </>
                                    :
                                        <Grid item xs={12}>
                                            <SelectTpsTravail timeSheet={timeSheet} setTimeSheet={setTimeSheet} />
                                        </Grid>
                                    }
                                    {timeSheet.companions.map((companion, index) => {
                                        return (
                                            <Grid container key={index} spacing={2} sx={{
                                                border: 1, borderColor: 'grey.500', display: 'flex',
                                                alignItems: 'center', justifyContent: 'center',
                                                pr: 1, boxShadow: 2, borderRadius: '10px', m:{
                                                xs: 0,
                                                sm: 1,
                                                md: 1,
                                                lg: 2,
                                                xl: 2}, ml: {xs: 2, sm: 1, lg: 2} }}>
                                                <Grid item xs={12} md={6}>
                                                    <SelectCompName index={index} companions={companions}
                                                                    setTimeSheet={setTimeSheet}
                                                                    timeSheet={timeSheet}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <SelectCompTravail index={index}
                                                                       setTimeSheet={setTimeSheet}
                                                                       timeSheet={timeSheet}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <RadioGroupComp index={index} setTimeSheet={setTimeSheet} timeSheet={timeSheet}/>
                                                </Grid>
                                                { timeSheet.companions[index].mode == 2 || timeSheet.companions[index].mode == 3 ?
                                                    <TpsTrajetComp index={index} setTimeSheet={setTimeSheet} timeSheet={timeSheet} />
                                                    :
                                                    <Grid item xs={12}>
                                                        <SelectZoneComp index={index}
                                                                        timeSheet={timeSheet}
                                                                        setTimeSheet={setTimeSheet}/>
                                                    </Grid>
                                                }
                                                { timeSheet.companions[index].commentChoice === 'oth' ?
                                                <>
                                                    <Grid item xs={12}>
                                                        <CommentChoice index={index}
                                                                        setTimeSheet={setTimeSheet}
                                                                        timeSheet={timeSheet}/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Comment index={index}
                                                                    setTimeSheet={setTimeSheet}
                                                                    timeSheet={timeSheet}/>
                                                    </Grid>
                                                </>
                                                :
                                                <Grid item xs={12}>
                                                    <CommentChoice index={index}
                                                                   setTimeSheet={setTimeSheet}
                                                                   timeSheet={timeSheet}/>
                                                </Grid>
                                                }
                                                <Button variant={"outlined"}
                                                sx={{
                                                    mb: 2
                                                }} color="error"
                                                onClick={deleteComp}>
                                                    Supprimer
                                                </Button>
                                            </Grid>
                                        )
                                    })}
                                    <Grid item xs={12} sx={{ mt: 1}}>
                                        <Button fullWidth variant="contained" color="inherit"
                                            onClick={addComp}>
                                                Ajouter un accompagnant
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="outlined"
                                                href='mailto:htirel@air8.fr;contact@air8.fr;abeaudu@air8.fr?subject='
                                                fullWidth>
                                            Envoyer un mail
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                    {isSubmited ?
                                        <Button fullWidth variant="contained"
                                        color="primary">
                                        <CircularProgress color="inherit"/>
                                        </Button>
                                        :
                                        editVerif.hasOwnProperty('order') ?
                                            <Button type="submit" fullWidth variant="contained" sx={{ height: '50px'}}
                                                    color="primary"
                                                    onClick={handleEdit}>
                                                Modifier
                                            </Button>
                                            :
                                        <Button type="submit" fullWidth variant="contained" sx={{ height: '50px'}}
                                                color="primary"
                                                onClick={handleSubmit}>
                                            Valider
                                        </Button>
                                    }
                                        </Grid>
                                </>
                            }
                        </Grid>
                    </Box>
                </Box>

            </Container>
        </ThemeProvider>
    )
}

export default connect(
    state => ({
    }),
)(Home)