import {createSlice} from "@reduxjs/toolkit";
import {assoc, clone, isEmpty} from "ramda";

//define initialstate, we want items in cart, cart status and the warehouseCode
const initialState = {
    id: null,
    itemsInCart: null,
    status: null,
    warehouseCode: null,
    created: null,
    warehouseSearch: null,
    warehouseEmail: null
}
//define slice
const cartSlice = createSlice({
    name: "cart",
    initialState,
    //define reducers
    reducers: {
        //we want to increase/decrease/update quantity, get quantity, erase the cart and change the status
        increaseItemQuantity: (state, action) => {
            //if cart is empty add one item to it
            if(state.itemsInCart === null ) {
                let obj = {}
                obj[action.payload.ItemCode] = {
                    itemCode: action.payload.ItemCode,
                    itemQuantity: 1,
                    itemName: action.payload.ItemName,
                    salesUnit: action.payload.SalesUnit
                }
                return {
                    ...state,
                    itemsInCart: obj,
                    status: 'active',
                    warehouseCode:
                        action.payload.WarehouseCode
                }
            } else {
                //if the item is already in the cart add +1 to his quantity
                // ajouter un ou pour le cas du panier
                if(state.itemsInCart.hasOwnProperty(action.payload.ItemCode)) {
                    let obj = clone(state.itemsInCart)
                    obj[action.payload.ItemCode].itemQuantity += 1
                    return {
                        ...state,
                            itemsInCart: obj
                    }
                } else if (state.itemsInCart.hasOwnProperty(action.payload)) {
                    // add quantity for cart, have to do else if because of ItemCode != itemCode
                    let obj = clone(state.itemsInCart)
                    obj[action.payload].itemQuantity += 1
                    return {
                        ...state,
                        itemsInCart: obj
                    }
                } else {
                    // add a new object to the state itemsInCart
                    return {
                        ...state,
                        itemsInCart: assoc(action.payload.ItemCode, {itemCode: action.payload.ItemCode, itemQuantity: 1, warehouseCode: action.payload.WarehouseCode, itemName: action.payload.ItemName, salesUnit: action.payload.SalesUnit}, state.itemsInCart)
                    }
                }
            }
        },
        decreaseItemQuantity: (state, action) => {
            //if the cart is empty do nothing
            if(state.itemsInCart != null) {
                // remove 1 to the quantity
                if(state.itemsInCart.hasOwnProperty(action.payload) && state.itemsInCart[action.payload].itemQuantity > 1){
                    let obj = clone(state.itemsInCart)
                    obj[action.payload].itemQuantity -= 1
                    return {
                        ...state,
                        itemsInCart: obj
                    }
                }else if (state.itemsInCart.hasOwnProperty(action.payload)) {
                    //remove the item from the cart
                    let obj = clone(state.itemsInCart)
                    delete obj[action.payload]
                    return {
                        ...state,
                        itemsInCart: obj
                    }
                }

            } else {
                console.log('aucun article dans le panier')
            }
        },
        setItemQuantity: (state, action) => {
            if (action.payload.itemQuantity > 0) {
                if (state.itemsInCart === null) {
                    let obj = {}
                    obj[action.payload.ItemCode] = {
                        itemCode: action.payload.ItemCode,
                        itemQuantity: action.payload.itemQuantity,
                        itemName: action.payload.ItemName,
                        salesUnit: action.payload.SalesUnit
                    }
                    return {
                        ...state,
                        itemsInCart: obj,
                        status: 'active',
                        warehouseCode:
                            action.payload.WarehouseCode
                    }
                } else if (state.itemsInCart.hasOwnProperty(action.payload.ItemCode)) {
                    let obj = clone(state.itemsInCart)
                    obj[action.payload.ItemCode].itemQuantity = action.payload.itemQuantity
                    return {
                        ...state,
                        itemsInCart: obj
                    }
                } else if (state.itemsInCart.hasOwnProperty(action.payload.itemCode)) {
                    let obj = clone(state.itemsInCart)
                    obj[action.payload.itemCode].itemQuantity = action.payload.itemQuantity
                    return {
                        ...state,
                        itemsInCart: obj
                    }
                } else {
                    return {
                        ...state,
                        itemsInCart: assoc(action.payload.ItemCode, {
                            itemCode: action.payload.ItemCode,
                            itemQuantity: action.payload.itemQuantity,
                            itemName: action.payload.ItemName,
                            salesUnit: action.payload.SalesUnit
                        }, state.itemsInCart)
                    }
                }
            } else if (action.payload.itemQuantity === 0) {
                let obj = clone(state.itemsInCart)
                delete obj[action.payload.ItemCode]
                return {
                    ...state,
                    itemsInCart: obj
                }
            }
        },
        ereaseCart: (state) => {
            return {
                ...state,
                    itemsInCart: null,
                    warehouseCode: null,
                    status: null,
                    created: null,
                    id: null,
                    warehouseSearch: null,
                    warehouseEmail: null
            }
        },
        ereaseOneItem: (state, action) => {
            let clonedItems = clone(state.itemsInCart)
            delete clonedItems[action.payload]
            if(isEmpty(clonedItems)) {
                return {
                    ...state,
                    itemsInCart: null,
                    warehouseCode: null,
                    status: null,
                    created: null,
                    id: null,
                    warehouseSearch: null
                }
            } else {
                return {
                    ...state,
                    itemsInCart: clonedItems
                }
            }
        },
        saveCart: (state) => {
            let str = 'save'
            return {
                ...state,
                    status: str
            }
        },
        validCart: (state) => {
            let str = 'valid'
            return {
                ...state,
                status: str
            }
        },
        replaceDataForCart: (state, action) => {
            if(action.payload != undefined && state.itemsInCart === null){
                return {
                    ...state,
                    id: action.payload.id,
                    status: action.payload.status,
                    warehouseCode: action.payload.warehouseCode,
                    itemsInCart: action.payload.itemsInCart,
                    created: action.payload.created,
                    warehouseSearch: action.payload.warehouseSearch
                }
            } else {
                return {
                    ...state,
                }
            }
        },
        setId: (state, action) => {
            if(action.payload != null){
                return {
                    ...state,
                    id: action.payload.id
                }
            }
        },
        setWarehouseSearch: (state, action) => {
            if(action.payload != null) {
                return {
                    ...state,
                    warehouseSearch: action.payload
                }
            }
        },
        setWarehouseEmail: (state, action) => {
            return {
                ...state,
                warehouseEmail: action.payload
            }
        }
    }
})

export const {actions, reducer} = cartSlice

export const {increaseItemQuantity, setWarehouseEmail, decreaseItemQuantity, ereaseCart, validCart, saveCart, replaceDataForCart, setId, setWarehouseSearch, setItemQuantity, ereaseOneItem} = actions