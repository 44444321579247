import Header from "../../components/timeSheet/Header";
import {Box, Button, Container, Slider, Typography} from "@mui/material";
import BottomNav from "../../components/shop/BottomNav";
import React, {useEffect} from "react";
import {usePosition} from "../../hooks/Position";
import {useNavigate} from "react-router-dom";

const Geoloc = () => {

    const userPosition = usePosition()
    const navigate = useNavigate()

    useEffect(() => {
        navigator.geolocation.watchPosition(function () {
            navigate('/magasin')
        })
    }, [])

    return (
        <Container component="main">
            <Header isAuth={true} />
            <Box sx={{marginTop: 18, display: 'flex', mb: 9,
                flexDirection: 'column', alignItems: 'center'}} >
                <Box noValidate
                     sx={{ mt: 0, boxShadow: 5, p: 1, width: {xs: '100%', sm: '100%', md: '80%', lg: '75%', xl: '75%'},
                         borderRadius: '10px', mb: 3, minHeight: {xs:'400px', sm:'4O0px', md:'350px', lg:'350px', xl:'350px'},
                         display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column'}}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant="h5" sx={{margin: 0, fontSize: 22 }}>Erreur géolocalisation</Typography>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', marginBottom: '20px', width: {xs: '100%', sm: '80%'}}}>
                                <Typography variant="h7" sx={{textAlign: 'center', margin: '5px 0px', pl: 2, pr: 2}}>
                                    Pour utiliser l'application vous devez autoriser l'accès à votre géolocalisation.
                                </Typography>
                                <Typography variant="h7" sx={{textAlign: 'center', margin: '5px 0px', pl: 2, pr: 2}}>
                                    Allez dans les paramètres de votre naviguateur pour données les permissions necessaire à l'application.
                                </Typography>
                                <Typography variant="h7" sx={{textAlign: 'center', margin: '5px 0px', pl: 2, pr: 2}}>
                                  Une fois les paramètres changés pouvez rafraichir la page grâce à ce boutton.
                                </Typography>
                             </Box>
                        <Button variant="outlined" onClick={() => window.location.reload()} sx={{mb: 2}}>Rafraichir</Button>
                </Box>
            </Box>
            <BottomNav />
        </Container>
    )
}

export default Geoloc