import React, {useState} from 'react';
import BottomNav from "../../components/shop/BottomNav";
import {Typography, Box, Container, Button, Slider, Skeleton} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {replaceProducts, replaceWarehouses} from "../../reducers/product/productSlice";
import Index from "../../components/shop/CallAndNav";
import SliderSkeleton from "../../components/shop/SliderSkeleton";
import HeaderShop from "../../components/shop/HeaderShop";
import StorefrontIcon from '@mui/icons-material/Storefront';

const SliderShop = ({items = null, dispatch, warehousesList = null, warehousesSearched = null,
                    warehousesDistance = null}) => {

    const [distance, setDistance] = useState(0)
    const [warehousesListToSend, setWarehousesListToSend] = useState([])

    const navigate = useNavigate()
    const navigateToshop = () => {
        let codeToTest = []
        warehousesListToSend.map((warehouse) => {
            codeToTest.push(warehouse.WarehouseCode)
        })
        let results = []
        items.map((item) => {
                item.warehouses.map((warehouse) => {
                    if (codeToTest.includes(warehouse.WarehouseCode) && !results.includes(item)) {

                            results.push(item)

                    }
                })
        })
        dispatch(replaceProducts(results))
        dispatch(replaceWarehouses(codeToTest))
        navigate('/shopping')
    }

    // fonction qui renvoie un array avec la liste des magasins présent
    const findWarehouseCloseEnough = () => {
        let warehouseListMatchingDistance = []
        warehousesDistance.map((e) => {
            if(e[0] < distance) {
                warehouseListMatchingDistance.push(warehousesList.find(el => el.WarehouseCode === e[1]))
            }
        })
        setWarehousesListToSend(warehouseListMatchingDistance)
    }

    //récup les infos du slider
    const handleChangeSlider = (e, newDistance) => {
        setDistance(newDistance)
        findWarehouseCloseEnough()
    }

    return (
        <Container component="main">
            <Index dispatch={dispatch} items={items} warehousesSearched={warehousesSearched}/>
            <HeaderShop isAuth={true} />
            <Box sx={{marginTop: {xs: 14, md: 18}, display: 'flex',
            flexDirection: 'column', alignItems: 'center'}} >
                <Box noValidate
                    sx={{ mt: 0, boxShadow: 5, p: 1, width: {xs: '100%', sm: '100%', md: '80%', lg: '75%', xl: '75%'},
                        borderRadius: '10px', mb: 9, minHeight: {xs:'400px', sm:'4O0px', md:'350px', lg:'350px', xl:'350px'},
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column'}}>
                    { warehousesList === null ?
                        <SliderSkeleton />
                        :
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant="h5" sx={{margin: 0}}>Trouver un magasin</Typography>
                                </Box>
                            </Box>
                            <Box sx={{minHeight: 250, display: 'flex', flexDirection: 'column', marginBottom: '20px', width: {xs: '100%', sm: '80%'}}}>
                                <Typography variant="h6" sx={{ textAlign: 'center'}}>Changer la distance :</Typography>
                                <Slider defaultValue={10} aria-label="Default" valueLabelDisplay="auto" sx={{width: '90%', padding: 2, margin: 'auto',
                                    marginTop: '5px', marginBottom: '15px'}} onChange={handleChangeSlider} min={10} max={500}/>
                                <Typography variant="h6" sx={{textAlign: 'center'}}>Il y a {warehousesListToSend.length} magasins a moins de {distance}km {warehousesListToSend.length > 0 && ':'}</Typography>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px'}}>
                                    {
                                        warehousesListToSend.map((e, index) => {
                                            return (
                                                <Box sx={{margin: '7px 0px', width: '90%', padding: '15px 7px',
                                                    display: 'flex', alignItems: 'center', borderRadius: '7px', boxShadow: 3}} key={index}>
                                                    <StorefrontIcon sx={{marginRight: '8px', ml:1}} />
                                                    {e.WarehouseName} / {e.City} / {Math.trunc(warehousesDistance.find(el => el[1] === e.WarehouseCode)[0])}km
                                                </Box>
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                            {
                                warehousesListToSend.length === 0 ?
                                    <Button variant="contained" fullWidth sx={{mb: 1}}>Rechercher</Button>
                                    :
                                    <Button variant="contained" fullWidth sx={{mb: 1}} onClick={navigateToshop}>Rechercher dans les {warehousesListToSend.length} magasins</Button>
                            }
                        </>
                    }

                </Box>
            </Box>
            <BottomNav />
        </Container>
    );
};

export default connect(
    state => ({
        items : state.productsReducer.items,
        warehousesList: state.productsReducer.warehousesList,
        warehousesSearched: state.productsReducer.warehouses,
        warehousesDistance: state.productsReducer.warehousesDistance
    })
)(SliderShop);