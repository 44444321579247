import React from 'react';
import InputLabel from "@mui/material/InputLabel";
import {Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

const CommentChoice = ({index=0, setTimeSheet, timeSheet}) => {

    const handleChange = (e) => {
        let timeSheetCopy = timeSheet
        timeSheetCopy.companions[index].commentChoice = e.target.value
        setTimeSheet({...timeSheetCopy})
    }

    return (
        <>
            <FormControl sx={{ minWidth: {
                    xs: 120,
                    sm: 170,
                    md: 220,
                    lg: 250,
                    xl: 250
                }, marginBottom: 2 }} fullWidth={true} >
                <InputLabel id="select-comment-comp-label">Commentaires</InputLabel>
                <Select
                    labelId="select-comment-comp-label"
                    id="select-comment-comp"
                    onChange={(e) => {
                        handleChange(e)
                    }}
                    autoWidth
                    value={timeSheet.companions[index].commentChoice}
                    label="Commentaires"
                >
                    <MenuItem value="rtd">Retard</MenuItem>
                    <MenuItem value="abs">Absent</MenuItem>
                    <MenuItem value="oth">Autres</MenuItem>
                </Select>
            </FormControl>
        </>
    );
};

export default CommentChoice;