import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PrivateRoute} from './PrivateRoute'
import OauthTokenReader from "./authentication/OauthTokenReader";
import Home from "./pages/timeSheet/Home";
import {PageLoader} from "./PageLoader";
import Login from "./authentication/Login"
import {connect} from "react-redux";
import List from "./pages/timeSheet/List";
import Shopping from './pages/shop/Shopping'
import ShoppingCart from './pages/shop/ShoppingCart';
import SliderShop from "./pages/shop/SliderShop";
import EditCartItem from "./pages/shop/EditCartItem";
import Confirm from "./pages/shop/Confirm";
import Geoloc from "./pages/shop/Geoloc";

export const App = ({
    loading= false,
    checkingAppCredentials= true,
    user= null
}) => {
    return (
        <>
            <OauthTokenReader />
                <BrowserRouter>
                    {
                        !checkingAppCredentials
                            ?
                            <Routes>
                                <Route element={<PrivateRoute user={user}/>}>
                                    <Route path="/" element={<Home/>}/>
                                    <Route path="/home" element={<Home/>}/>
                                    <Route path="/shopping" element={<Shopping/>}/>
                                    <Route path="/cart" element={<ShoppingCart />} />
                                    <Route path="/magasin" element={<SliderShop />} />
                                    <Route path="/cart/*" element={<SliderShop />} />
                                    <Route path="/geoloc" element={<Geoloc />} />
                                    <Route path="/cart/:magasinId/:itemId" element={<EditCartItem />} />
                                    <Route path="/confirm" element={<Confirm />} />
                                    <Route path="/login" element={<Login/>}/>
                                    <Route path="/list/:year/:week" element={<List />}/>
                                    <Route path="/edit/:date/:order" element={<Home />}/>
                                </Route>
                            </Routes>
                            :
                            <PageLoader pageLoading={checkingAppCredentials} caption="Authentification en cours..."/>
                    }
                </BrowserRouter>
        </>
    )
};

export default connect(state => ({
    loading: state.loginReducer.loading,
    checkingAppCredentials: state.loginReducer.checkingAppCredentials,
    user: state.loginReducer.user
}))(App)