import React from "react";
import {createSlice} from "@reduxjs/toolkit";
import {authenticate} from "./OAuth2Utils";
import {fetchToken} from "./OauthTokenReader";
import {connect} from "react-redux";
import {Box, Button, createTheme, CssBaseline, TextField, Container, Typography} from "@mui/material";
import {ThemeProvider} from "@mui/styles";
import Header from "../components/timeSheet/Header";

export const initialState = {
    login: '',
    password: '',
    loading: false,
    loaded: false,
    user: null,
    checkingAppCredentials : true,
    referer : '',
};

export const loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        setLogin: (state, action) => ({
            ...state,
            login: action.payload
        }),
        setReferer: (state, action) => ({
            ...state,
            referer: action.payload
        }),
        setPassword: (state, action) => ({
            ...state,
            password: action.payload
        }),
        setLoading: (state, action) => ({
            ...state,
            loading: action.payload,
            loaded: action.payload ? false : state.loaded
        }),
        setLoaded: (state, action) => ({
            ...state,
            loaded: action.payload
        }),
        setUser: (state, action) => {
            return({
                ...state,
                user: action.payload,
                checkingAppCredentials: false
            })
        },
        setCheckingAppCredentials: (state, action) => ({
            ...state,
            checkingAppCredentials: action.payload
        })
    }
});

export const {actions, reducer} = loginSlice;

export const {
    setLogin,
    setPassword,
    setLoading,
    setLoaded,
    setReferer,
    setUser,
    setCheckingAppCredentials
} = actions;

export const sendLogin = ({login, password}) => dispatch => {
    dispatch(setLoading(true));
    authenticate({login, password})
        .then(data => {
            if (data.hasOwnProperty('access_token') && data.access_token !== '') {
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                dispatch(fetchToken());
            } else {
                dispatch(setLoading(false));
            }
        });
};

export const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.pathname = '/login';
};

const theme = createTheme()

export const Login = ({
    login = '',
    password = '',
    dispatch,
    ...rest
}) => {
    return(
        <ThemeProvider theme={theme}>
            <Header isAuth={false}/>
            <Container component="main" maxWidth="xs"
            sx={{
                mt: 19
            }}>
                <CssBaseline />
                <Box sx={{
                    marginTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 5,
                    alignItems: 'center',
                    p: 5,
                    borderRadius: '10px'
                }}>
                    <Typography component="h1" variant="h5">
                        Se connecter
                    </Typography>
                    <Box component="form"
                    noValidate
                    sx={{ mt: 1 }}>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Identifiant"
                        name="identifiant"
                        autoComplete="off"
                        onChange={(e) => {
                            dispatch(setLogin(e.target.value))
                        }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Mot de passe"
                            name="password"
                            autoComplete="off"
                            type="password"
                            onChange={(e) => {
                                dispatch(setPassword(e.target.value))
                            }}
                        />
                        <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={(e) => {
                            dispatch(sendLogin({login, password}))
                        }}>
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default connect(
    state => ({
        login : state.loginReducer.login,
        password : state.loginReducer.password,
    }),
)(Login)