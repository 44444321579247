import React from 'react';
import {Box, Button, Skeleton, Stepper, Step, StepLabel} from "@mui/material";

const arrayToGenerateAccordion = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]


const ShoppingSkeleton = () => {
    return (
        <>
            <Box noValidate sx={{ mt: 1, boxShadow: 5, p: 1, width: {xs: '100%', sm: '100%', md: '80%', lg: '75%', xl: '75%'},
                borderRadius: '10px', mb: 3, minHeight: '500px'}}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                    <Button variant="outlined" sx={{ mb: 1 }} fullWidth>
                        <Skeleton variant={"circular"} height={30} width={30}/>
                    </Button>
                </Box>
                <Box sx={{minHeight: 350, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <Skeleton variant={"rectangular"} height={55} sx={{width: '100%'}} />
                    <Box>
                        {arrayToGenerateAccordion.map(e => {
                            return (
                                <Box sx={{padding: 1, mt: 1, mb: 1, borderRadius: 1, boxShadow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} key={e}>
                                    <Skeleton variant={"rectangular"} height={40} width={250}/>
                                    <Skeleton variant={"circular"} height={30} width={30}/>
                                </Box>
                            )
                        })}
                    </Box>
                    <Button variant="contained" sx={{ mt: 1 }}>
                        <Skeleton variant={"circular"} height={30} width={30}/>
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default ShoppingSkeleton;