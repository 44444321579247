import React, {useEffect, useState} from 'react';
import {Alert, Box, Button, Container, CssBaseline, Modal, Skeleton, TextField, Typography, Stack} from "@mui/material";
import BottomNav from "../../components/shop/BottomNav";
import {connect} from "react-redux";
import { apiPost} from "../../authentication/OAuth2Utils";
import {ereaseCart, validCart} from "../../reducers/cart/cartSlice"
import {ereaseWarehouses} from "../../reducers/product/productSlice";
import Link from '@mui/material/Link';
import {useNavigate} from "react-router-dom";
import HeaderShop from "../../components/shop/HeaderShop";
import Index from "../../components/shop/CallAndNav";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ConfirmSkeleton from "../../components/shop/ConfirmSkeleton";
import {usePosition} from "../../hooks/Position";

const Confirm = ({cart = null, dispatch = null, items = null,
                     warehousesSearched = null, warehousesList = null}) => {

    const [inputValue, setInputValue] = useState('')
    const [confirmSuccess, setConfirmSuccess] = useState(false)
    const [confirmError, setConfirmError] = useState(false)
    const navigate = useNavigate()
    const [cancelLoading, setCancelLoading] = useState(false)
    const [openGps, setOpenGps] = useState(false)
    const [currentWarehouse, setCurrentWarehouse] = useState(null)
    const [currentWarehouseLati, setCurrentWarehouseLati] = useState('')
    const [currentWarehouseLong, setCurrentWarehouseLong] = useState('')
    const [openCancelCommand, setOpenCancelCommand] = useState(false)
    const userPosition = usePosition()

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '2px solid #707070',
        boxShadow: 24,
        p: 4,
        borderRadius: '15px'
    }

    const handleSubmitBtn = (inputValue) => {
        let toSend = {id: cart.id, token: inputValue}
        apiPost('/api/confirm', toSend)
            .then(data => {
                setInputValue('')
                if(data.status === 'valid'){
                    setConfirmSuccess(true)
                    setConfirmError(false)
                    dispatch(ereaseCart())
                    dispatch(ereaseWarehouses())
                } else {
                    setConfirmError(true)
                    setConfirmSuccess(false)
                }
            })
    }

    //annuler la commande
    const handleCancelingCart = () => {
        setCancelLoading(true)
        if(cart.id != null) {
            let toSend = cart.id
            apiPost('/api/cancelcart', toSend).then(data => {
                if (data.status === 'valid') {
                    dispatch(ereaseCart())
                    dispatch(ereaseWarehouses())
                    setCancelLoading(false)
                    navigate('/magasin')
                }
            })
        }
    }

    useEffect(() => {
        if(cart !== null && warehousesList !== null) {
            warehousesList.map((e) => {
                if(e.WarehouseCode == cart.warehouseCode) {
                    setCurrentWarehouse(e)
                    setCurrentWarehouseLong(e.U_W3C_LONG)
                    setCurrentWarehouseLati(e.U_W3C_LATI)
                }
            })
        }
    }, [warehousesList])

    const openNewTabMap = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    return (
        <Container component="main">
            <Index dispatch={dispatch} items={items} warehousesSearched={warehousesSearched}/>
            <HeaderShop isAuth={true} cart={cart} />
            <CssBaseline />
            <Box
                sx={{
                    marginTop: {xs: 15, sm: 16, md: 18},
                    mb: 9,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }} >
                {items != null ?
                    <>
                        <Box noValidate
                             sx={{ mt: 1, boxShadow: 5, p: 1, width: {xs: '100%', sm: '100%', md: '80%', lg: '75%', xl: '75%'},
                                 borderRadius: '10px', mb: 3,
                                 minHeight: '420px'}}>

                            <Modal open={openGps} onClose={() => {setOpenGps(false)}}>
                                <Box sx={styleModal}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                                        <Button variant="outlined" sx={{marginBottom: '30px'}} onClick={() => {
                                            openNewTabMap(`https://www.waze.com/ul?ll=${currentWarehouseLati}%2C${currentWarehouseLong}&navigate=yes`)
                                        }}>Utiliser Waze</Button>
                                        <Button variant="outlined" sx={{marginBottom: '30px'}} onClick={() => {
                                            openNewTabMap(`https://www.google.com/maps/dir/${userPosition.latitude},${userPosition.longitude}/${currentWarehouseLati},${currentWarehouseLong}`)
                                        }}>Utiliser Maps</Button>
                                        <Button variant="outlined" onClick={() => {
                                            openNewTabMap(`https://maps.apple.com/?saddr=${userPosition.latitude},${userPosition.longitude}&daddr=${currentWarehouseLati},${currentWarehouseLong}`)
                                        }}>Utiliser Plans</Button>
                                    </Box>
                                </Box>
                            </Modal>
                            <Modal open={openCancelCommand} onClose={() => {setOpenCancelCommand(false)}}>
                                <Box sx={styleModal}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                                        <Typography variant="h6" sx={{ textAlign: 'center' }}>
                                            Êtes-vous sûr de vouloir annuler votre commande ?
                                        </Typography>
                                        <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'space-evenly' }}>
                                            <Button variant="outlined" color="inherit" size="large"
                                                    onClick={() => {setOpenCancelCommand(false)}}>
                                                Non
                                            </Button>
                                            <Button variant="outlined" size="large" color="error"
                                                    onClick={() => {
                                                        handleCancelingCart()
                                                        setOpenCancelCommand(false)
                                                    }}>
                                                Oui
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Modal>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                    <>
                                        {cart.id !== null ?
                                            <>
                                                <Typography variant="h5" sx={{margin: 0, fontSize: {xs: 18, sm: 20, md: 24}, mr: {xs: 1, sm: 0} }}>Récupérer la commande n°{cart.id}</Typography>
                                                <Box sx={{ backgroundColor: '#1976d2', position: 'relative', left: {xs: 0,sm: 130,md: 150, lg: 170, xl: 170}, width: '34px', height: '34px', borderRadius: '17px', display: 'flex',
                                                    justifyContent: 'center', alignItems: 'center', cursor: 'pointer', boxShadow: 5}}>
                                                    <LocationOnIcon onClick={() => {setOpenGps(true)}} sx={{color: 'white'}} />
                                                </Box>
                                            </>
                                            :
                                            <Typography variant="h5" sx={{margin: 0, fontSize: {xs: 18, sm: 20, md: 24}, mr: {xs: 1, sm: 0} }}>Récupérer la commande</Typography>
                                        }
                                    </>
                                </Box>
                            </Box>
                            <Box sx={{minHeight: 200, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                               <Typography variant="string" sx={{ textAlign: 'center', fontSize: {xs: 16, md: 18}, margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'} }}>
                                    Le magasin a reçu votre panier par e-mail.
                                </Typography>
                                <Typography variant="string" sx={{ textAlign: 'center', fontSize: {xs: 16, md: 18}, margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'} }}>
                                    Pour récupérer votre commande il faut renseigner le code fourni par le magasinier auprès duquel vous récupérez le matériel.
                                </Typography>
                                <Typography variant="string" sx={{ textAlign: 'center', fontSize: {xs: 16, md: 18}, margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'} }}>
                                    Si le code ne fonctionne pas ou si la personne n'est pas présente merci de vous adresser au service administratif : <Link href="tel:0887878787" underline="none">08 87 87 87 87</Link>
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                                {confirmSuccess && <Alert severity="success" sx={{margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'} }}>Commande validée !</Alert>}
                                {confirmError && <Alert severity="error" sx={{margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'}}}>Le code n'est pas bon.</Alert>}
                                <TextField variant="outlined" label="Code..." sx={{margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'} }} onChange={(newValue) => setInputValue(newValue.target.value)}/>
                                {cart.id !== null ?
                                    <>
                                        <Button variant="contained" sx={{margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'}, fontSize: {xs: 14, md: 18} }} onClick={() => handleSubmitBtn(inputValue)}>Valider la commande</Button>
                                        {cancelLoading ?
                                            <Button variant="outlined" color="error" sx={{margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 20px 40px'}}}><Skeleton width={80} height={20} /></Button>
                                            :
                                            <Button variant="outlined" color="error" sx={{margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 20px 40px'}, fontSize: {xs: 14, md: 18}}} onClick={() => setOpenCancelCommand(true)}>L'annuler</Button>
                                        }
                                    </>
                                    :
                                    <Button variant="contained" sx={{margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'}, fontSize: {xs: 14, md: 18} }} onClick={() => {navigate('/magasin')}}>Passer une autre commande</Button>
                                }
                              </Box>

                        </Box>
                    </>
                    :
                    <ConfirmSkeleton />
                }
            </Box>
            <BottomNav />
        </Container>
    );
};

export default connect(
    state => ({
        cart: state.cartReducer,
        items: state.productsReducer.items,
        warehousesSearched: state.productsReducer.warehouses,
        warehousesList: state.productsReducer.warehousesList
    })
)(Confirm)