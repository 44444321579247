import React, {useState} from 'react';
import {
    AccordionSummary,
    Button,
    AccordionDetails,
    Accordion,
    Modal,
    Box,
    Typography,
    Grid,
    CircularProgress
} from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    ereaseCart,
    increaseItemQuantity, setId,
    setWarehouseEmail,
    setWarehouseSearch
} from "../../../reducers/cart/cartSlice";
import formatNumber from "../../../Utils";
import {clone} from "ramda";
import {apiPost} from "../../../authentication/OAuth2Utils";
import {ereaseWarehouses} from "../../../reducers/product/productSlice";

const AccordionList = ({item, index, itemsInCart, cartWarehouseCode, cartStatus, warehousesCode, warehousesList, warehousesDistance, cart, warehouseSearched}) => {

    const dispatch = useDispatch()
    const [isSaving, setIsSaving] = useState(false)

    // récupérer le magasin pour la modal
    const [magasinToGo, setMagasinToGo] = useState()

    // controler le comportement des accordéons
    const [expanded, setExpanded] = useState(false)
    const handleChangeAccor = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const navigate = useNavigate()
    const navigateToEdit = (magId, itemId) => {
        if(cartStatus != 'valid') {
            navigate(`/cart/${magId}/${itemId}`)
        }
    }

    // gérer la modal
    const [open, setOpen] = useState(false)
    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '2px solid #707070',
        boxShadow: 24,
        p: 4,
        borderRadius: '15px'
    }

    const postCloned = (clonedCart, warehouse) => {
        apiPost('/api/savecart', clonedCart).then(data => {
            if(data.status === 'valid'){
                dispatch(setId(data.cart))
                setIsSaving(false)
                navigateToEdit(warehouse, item.ItemCode)
            }
        })
    }

    // gérer la navigation pour éditer l'item
    const handleAccorClick = (item, warehouse) => {
        setIsSaving(true)
        let clonedCart = clone(cart)
        let currWarehouse = warehousesList.find(e => e.WarehouseCode === warehouse)
        if(itemsInCart != null) {
            // si l'item est présent dans le panier alors naviguer
            if(warehouse === cartWarehouseCode) {
                // ajouter ici l'ajout d'un nouvel item
                if(itemsInCart[item.ItemCode]) {
                    navigateToEdit(warehouse, item.ItemCode)
                } else {
                    let obj = {
                        ItemCode: item.ItemCode,
                        ItemName: item.ItemName,
                        itemQuantity: 1,
                        SalesUnit: item.SalesUnit,
                        WarehouseCode: currWarehouse.WarehouseCode
                    }
                    clonedCart[item.ItemCode] = obj
                    dispatch(increaseItemQuantity(obj))
                    postCloned(clonedCart, warehouse)
                }
            } else {
                // sinon afficher la modal et remplacer le warehouse code
                if(cartStatus != 'valid') {
                    setOpen(true)
                }
                setMagasinToGo(warehouse)
            }
        } else {
            // si le panier est vide alors il faut l'initialiser
            let obj = {}
            obj[item.ItemCode] = {
                ItemCode: item.ItemCode,
                ItemName: item.ItemName,
                itemQuantity: 1,
                SalesUnit: item.SalesUnit,
                WarehouseCode: currWarehouse.WarehouseCode
            }
            clonedCart.itemsInCart = obj
            clonedCart.status = 'active'
            clonedCart.warehouseCode = currWarehouse.WarehouseCode
            clonedCart.warehouseSearch = warehouseSearched
            clonedCart.warehouseEmail = currWarehouse.U_W3C_WMEL
            dispatch(setWarehouseEmail(currWarehouse.U_W3C_WMEL))
            dispatch(increaseItemQuantity(obj[item.ItemCode]))
            dispatch(setWarehouseSearch(warehouseSearched))
            postCloned(clonedCart, warehouse)
        }
    }

    function sortItemsWhenActiveWarehouse(itemsToSort) {
        if(cartWarehouseCode !== null) {
            let orderedItem = itemsToSort.sort((a,b) => {
                if(a.WarehouseCode === cartWarehouseCode) {
                    return -1
                }

                if(b.WarehouseCode != cartWarehouseCode) {
                    return  1
                }

                return 0
            })

            return orderedItem
        }
    }

    let itemWarehousesCloned = clone(item.warehouses)

    return (
        <>
            <Modal open={open} onClose={() => {setOpen(false)}}>
                <Box sx={styleModal}>
                    <Typography variant="h6" sx={{ textAlign: 'center' }}>
                        Vous avez sélectionné un article provenant d'un autre magasin. Souhaitez-vous créer un nouveau panier et vider l'actuel ?
                    </Typography>
                    <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'space-evenly' }}>
                        <Button variant="outlined" color="inherit" size="large"
                                onClick={() => {
                                    setOpen(false)
                                    setIsSaving(false)
                                }}>
                            Non
                        </Button>
                        <Button variant="outlined" size="large"
                            onClick={() => {
                                setOpen(false)
                                dispatch(ereaseCart())
                                setIsSaving(false)
                                navigateToEdit(magasinToGo, item.ItemCode)
                                }}>
                            Oui
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Accordion expanded={expanded === `panel${index}`} onChange={handleChangeAccor(`panel${index}`)}
                       sx={{padding: 1, mt: 1, mb: 1, borderRadius: 1}} key={index}>
                {itemsInCart != null ?
                    item.warehouses.find(e => e.WarehouseCode === cartWarehouseCode) ?
                        <AccordionSummary expandIcon={<PlayCircleIcon sx={{ color: 'black'}} />}
                                          aria-controls="panel1a-content" id={'panel' + index}>
                            <Typography variant="string" sx={{ fontSize:{xs: '14px', sm: '16px', md: '18px'} }}>{item.ItemName}</Typography>
                        </AccordionSummary>
                        :
                        <AccordionSummary expandIcon={<PlayCircleIcon sx={{ color: '#DCDCDC'}} />}
                                          aria-controls="panel1a-content" id={'panel' + index}>
                            <Typography variant="string" sx={{ fontSize:{xs: '14px', sm: '16px', md: '18px'} }}>{item.ItemName}</Typography>
                        </AccordionSummary>
                :
                    <AccordionSummary expandIcon={<PlayCircleIcon sx={{ color: 'black'}} />}
                                      aria-controls="panel1a-content" id={'panel' + index}>
                        <Typography variant="string" sx={{ fontSize:{xs: '14px', sm: '16px', md: '18px'} }}>{item.ItemName}</Typography>
                    </AccordionSummary>
                }
                <AccordionDetails>
                    {itemsInCart === null ?
                        item.warehouses.map((e, index) => {
                            if(warehousesCode != null && warehousesCode.includes(e.WarehouseCode)) {
                                return (
                                    <Grid container spacing={2} key={index} >
                                        <Grid item xs={5} md={6} alignItems="center" sx={{ fontSize:{xs: 14, sm: 14, md: 16, lg: 18} }}>
                                            <p>Magasin: {warehousesList.find(el => el.WarehouseCode === e.WarehouseCode).WarehouseName} / {Math.trunc(warehousesDistance.find(el => el[1] === e.WarehouseCode)[0])}km</p>
                                        </Grid>
                                        <Grid item xs={4} alignItems="center" sx={{ fontSize:{xs: 14, sm: 15, md: 16, lg: 18} }}>
                                            <p>{formatNumber(e.InStock)}{item.SalesPackagingUnit != null ? ' ' + item.SalesPackagingUnit : ' unités'}</p>
                                        </Grid>
                                        <Grid item xs={2} alignItems="center">
                                            <Button variant="outlined" onClick={() => {handleAccorClick(item, e.WarehouseCode)}} sx={{mt: '9px'}}>
                                                {isSaving ?
                                                    <CircularProgress size={20} />
                                                    :
                                                    <AddShoppingCartIcon sx={{color: 'primary.dark', textDecoration: 'none'}}/>
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        })
                    :
                        sortItemsWhenActiveWarehouse(itemWarehousesCloned).map((e, index) => {
                            if (e.WarehouseCode === cartWarehouseCode) {
                                return (
                                    <Grid container spacing={2} key={index}>
                                        <Grid item xs={9} md={10} alignItems="center"
                                              sx={{fontSize: {xs: 14, sm: 14, md: 16, lg: 18}}}>
                                            <p>Stock disponible dans votre magasin : {formatNumber(e.InStock)}{item.SalesPackagingUnit != null ? ' ' + item.SalesPackagingUnit : ' unités'}</p>
                                        </Grid>
                                        <Grid item xs={2} alignItems="center">
                                            <Button variant="outlined" onClick={() => {
                                                handleAccorClick(item, e.WarehouseCode)
                                            }} sx={{mt: '9px'}}>
                                                {isSaving ?
                                                    <CircularProgress size={20}/>
                                                    :
                                                    <AddShoppingCartIcon
                                                        sx={{color: 'primary.dark', textDecoration: 'none'}}/>
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )
                            } else if(warehousesCode.includes(e.WarehouseCode)) {
                                return (
                                    <Grid container spacing={2} key={index} sx={{mb: 1}}>
                                        <Grid item xs={9} md={10} alignItems="center"
                                              sx={{fontSize: {xs: 14, sm: 14, md: 16, lg: 18}}}>
                                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                <p style={{color: 'rgb(215,215,215)', margin: 0}}>Magasin: {warehousesList.find(el => el.WarehouseCode === e.WarehouseCode).WarehouseName} / {Math.trunc(warehousesDistance.find(el => el[1] === e.WarehouseCode)[0])}km</p>
                                                <p style={{color: 'rgb(215,215,215)', margin: 0}}>{formatNumber(e.InStock)}{item.SalesPackagingUnit != null ? ' ' + item.SalesPackagingUnit : ' unités'}</p>
                                            </Box>
                                         </Grid>
                                        <Grid item xs={2} alignItems="center">
                                            <Button variant="outlined" onClick={() => {
                                                handleAccorClick(item, e.WarehouseCode)
                                            }} sx={{mt: '9px'}}>
                                                {isSaving ?
                                                    <CircularProgress size={20}/>
                                                    :
                                                    <AddShoppingCartIcon
                                                        sx={{color: 'primary.dark', textDecoration: 'none'}}/>
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )

                            }
                        })
                    }
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default AccordionList;