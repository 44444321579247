import React, {useEffect} from 'react';
import FormControl from "@mui/material/FormControl";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {useDispatch} from "react-redux";
import {assoc, until} from "ramda";

const RadioGroupForm = ({timeSheet, setTimeSheet}) => {

    const handleChange = (e) => {
        setTimeSheet(assoc('mode', parseInt(e.target.value), timeSheet))
    }

    return (
        <FormControl spacing={3} fullWidth={true}>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={timeSheet.mode}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <FormControlLabel control={<Radio />} value={1} label="local" onChange={handleChange} />
                <FormControlLabel control={<Radio />} value={3} label="GDSN" onChange={handleChange} />
                <FormControlLabel control={<Radio />} value={2} label="Grand déplacement" onChange={handleChange} />
            </RadioGroup>
        </FormControl>
    );
};

export default RadioGroupForm;