import React, {useEffect, useState} from 'react';
import {AppBar, Box, Step, StepLabel, Stepper, Toolbar, Typography} from "@mui/material";
import Logo from "../../../styles/assests/air8_logo.jpg";
import {logout} from "../../../authentication/Login";
import OutputRoundedIcon from "@mui/icons-material/OutputRounded";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";

const HeaderShop = ({isAuth, warehousesDistance = null, warehousesList = null, warehouseCode = null}) => {

    const navigate = useNavigate()

    return (
        <AppBar>
            <Toolbar sx={{
                display: 'flex',
                justifyContent: 'space-around',
                backgroundColor: 'white',
                p: 2
            }}>
                <div onClick={() => {navigate('/')}} style={{cursor: "pointer"}}>
                    <CalendarTodayIcon color="primary"
                                    fontSize="large"
                                    sx={{
                                        position: 'relative',
                                        // left: {
                                        //     xs: -30,
                                        //     sm: -30,
                                        //     md: -50,
                                        //     lg: -50,
                                        //     xl: -50
                                        // }
                                    }}/>
                </div>
                {warehouseCode !== null ?
                    <Box
                        component="img"
                        sx={{
                            width: {
                                xs: 80,
                                sm: 120,
                                md: 130,
                                lg: 140,
                                xl: 150
                            },
                        }}
                        alt="air8 logo"
                        src={Logo}
                    />
                :

                    <Box
                        component="img"
                        sx={{
                            width: {
                                xs: 100,
                                sm: 130,
                                md: 120,
                                lg: 150,
                                xl: 150
                            },
                        }}
                        alt="air8 logo"
                        src={Logo}
                    />
                }

                {isAuth &&
                    <>
                        <Box onClick={logout} sx={{
                            // width: {
                            //     xs: 100,
                            //     sm: 130,
                            //     md: 120,
                            //     lg: 150,
                            //     xl: 150
                            // }, position: 'relative',
                            // right: {
                            //     xs: -30,
                            //     sm: -30,
                            //     md: -50,
                            //     lg: -50,
                            //     xl: -50
                            // },
                            cursor: "pointer"
                        }}>
                            <OutputRoundedIcon color="primary"
                                               fontSize="large" />
                        </Box>
                    </>
                }
            </Toolbar>
            {warehouseCode !== null &&
                <Typography sx={{mt: 1, mb: 1, textAlign: 'center'}}>
                    Magasin acutel : {warehousesList.find(e => e.WarehouseCode === warehouseCode).WarehouseName} à {Math.trunc(warehousesDistance.find(el => el[1] === warehouseCode)[0])}km
                </Typography>
            }
        </AppBar>
    );
};

export default connect(
    state => ({
        warehousesDistance: state.productsReducer.warehousesDistance,
        warehousesList: state.productsReducer.warehousesList,
        warehouseCode: state.cartReducer.warehouseCode
    })
)(HeaderShop)