import React from 'react';
import InputLabel from "@mui/material/InputLabel";
import {Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {assoc} from "ramda";

const SelectCompTravail = ({index=0, setTimeSheet, timeSheet}) => {

    const tps = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12]

    const handleChange = (e) => {
        let timeSheetCopy = timeSheet
        timeSheetCopy.companions[index].hours = e.target.value
        setTimeSheet({...timeSheetCopy})
    }

    return (
        <div>
            <FormControl sx={{ minWidth: {
                    xs: 120,
                    sm: 170,
                    md: 220,
                    lg: 250,
                    xl: 250
                }, marginBottom: 2 }} fullWidth={true} spacing={3}>
                <InputLabel id="simple-select-tps-travail-comp-label">Heures de travail</InputLabel>
                <Select
                    labelId="simple-select-tps-travail-comp-label"
                    id="simple-select-tps-travail-comp"
                    onChange={(e) => {
                        handleChange(e)
                    }}
                    fullWidth={true}
                    value={timeSheet.companions[index].hours}
                    label="Temps de travail"
                >
                    { tps.map((tps, ind) => {
                        return (
                            <MenuItem value={tps} key={ind}>{tps}</MenuItem>
                        )
                    })
                    }
                </Select>
            </FormControl>
        </div>
    );
};

export default SelectCompTravail;