import React, {createContext, useContext, useEffect, useState} from "react"

const viewportContext = createContext({})

export const ViewportProvider = ({
                                     children
                                 }) => {
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    const [size, setSize] = useState(getSize(window.innerWidth))

    const handleWindowResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
        setSize(getSize(window.innerWidth))
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize)
        return () => {
            window.removeEventListener("resize", handleWindowResize)
        };
    }, [true]);

    return (
        <viewportContext.Provider value={{width, height, size}}>
            {children}
        </viewportContext.Provider>
    )
}

export const useViewport = () => {
    const {width, height, size} = useContext(viewportContext)
    return {width, height, size}
}

const getSize = (width) => {
    if(width < 600 && width > 0){
        return 'xs'
    } else if(width < 768){
        return 'sm'
    } else if(width < 992){
        return 'md'
    } else if(width < 1200){
        return 'lg'
    } else {
        return 'xl'
    }
}