import React from 'react';
import {TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";

const Comment = ({index=0, timeSheet, setTimeSheet}) => {

    const handleChange = (e) => {
        let timeSheetCopy = timeSheet
        timeSheetCopy.companions[index].comment = e.target.value
        setTimeSheet({...timeSheetCopy})
    }

    return (
        <div>
            <FormControl sx={{ minWidth: {
                xs: 120,
                sm: 170,
                md: 220,
                lg: 250,
                xl: 250
            }, marginBottom: 2 }} fullWidth={true} >
                <TextField id="outlined-basic" label="Précisions" variant="outlined" onChange={(e) => {
                    handleChange(e)
                }} value={timeSheet.companions[index].comment}/>
            </FormControl>
        </div>
    );
};

export default Comment;