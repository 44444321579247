import React from 'react';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import {assoc} from "ramda";

const SelectZone = ({timeSheet, setTimeSheet}) => {

    const zones = [1, 2, 3, 4, 5]

    const handleChange = (e) => {
        setTimeSheet(assoc('zone', e.target.value, timeSheet))
    }

    return (
        <div>
            <FormControl sx={{ minWidth: 250, marginBottom: 2 }} fullWidth={true} >
                <InputLabel id="demo-simple-select-zone-label">Zone</InputLabel>
                <Select
                    labelId="demo-simple-select-zone-label"
                    id="demo-simple-select-zone"
                    onChange={handleChange}
                    autoWidth
                    value={timeSheet.zone}
                    label="Zone"
                >
                    { zones.map((zone, index) => {
                        return (
                            <MenuItem value={zone} key={index}>Zone {zone}</MenuItem>
                            )
                    })
                    }
                </Select>
            </FormControl>
        </div>
    );
};

export default SelectZone;