import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import {reducer as loginReducer} from "./authentication/Login";
import {reducer as pageLoaderReducer} from "./PageLoader";
import {reducer as formReducer } from "./reducers/form/formSlice";
import {reducer as productsReducer} from "./reducers/product/productSlice"
import {reducer as cartReducer} from "./reducers/cart/cartSlice"
import {ViewportProvider} from "./hooks/ViewportContext";

export const store = configureStore({
    reducer: {
        loginReducer,
        pageLoaderReducer,
        formReducer,
        productsReducer,
        cartReducer
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ViewportProvider>
            <App/>
        </ViewportProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
