import React, {useEffect, useState} from 'react';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {Select} from "@mui/material";
import {assoc} from "ramda";

const SelectChantier = ({site = [], setTimeSheet, timeSheet}) => {

    const handleChange = (e) => {
        let timeSheetCopy = timeSheet
        timeSheetCopy.site = e.target.value
        setTimeSheet({...timeSheetCopy})
        timeSheet.companions.map((companion, index) => {
            let timeSheetCopy2 = timeSheet
            timeSheetCopy2.companions[index].site = e.target.value
            setTimeSheet({...timeSheetCopy2})
        })
    }

    useEffect(() => {
        if(timeSheet.site === null) {
            setTimeSheet(assoc('site', site[0].id, timeSheet))
        }
    }, [])

    return (
        <div>
            <FormControl sx={{ minWidth: {
                    xs: 120,
                    sm: 170,
                    md: 220,
                    lg: 250,
                    xl: 250
                }, marginBottom: 2 }} fullWidth={true}>
                <InputLabel id="demo-simple-select-autowidth-label">Chantier</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    onChange={handleChange}
                    autoWidth
                    value={timeSheet.site}
                    label="Chantier"
                >
                    {site.map((ord, index) => {
                            return (
                                <MenuItem key={index} value={ord.id}>{ord.id + ' - ' + ord.name}</MenuItem>
                            )
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

export default SelectChantier;