import React from 'react';
import {
    Box,
    CircularProgress,
    Grid,
    Table,
    Paper,
    Skeleton,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TableRow, Button
} from "@mui/material";
import {useViewport} from "../../../hooks/ViewportContext";

const SkeletonSite = () => {

    const {width, height, size} = useViewport()

    return (
        <Box sx={{minHeight: 350, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table sx={{ minWidth: {
                        xs: 250,
                        sm: 350,
                        md: 550,
                        lg: 650,
                        xl: 650}
                }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><Skeleton animation="wave" height={30} width="50%" /></TableCell>
                            <TableCell><Skeleton animation="wave" height={30} width="50%" /></TableCell>
                            <TableCell><Skeleton animation="wave" height={30} width="50%" /></TableCell>
                            {width < 600 ?
                                null
                                :
                                <TableCell><Skeleton animation="wave" height={30} width="50%" /></TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2} }}>
                                <Skeleton animation="wave" height={20} width="20%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={30} width="100%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={20} width="20%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={50} width="50%" />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2} }}>
                                <Skeleton animation="wave" height={20} width="20%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={30} width="100%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={20} width="20%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={50} width="50%" />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2} }}>
                                <Skeleton animation="wave" height={20} width="20%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={30} width="90%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={20} width="20%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={50} width="50%" />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2} }}>
                                <Skeleton animation="wave" height={20} width="20%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={30} width="80%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={20} width="20%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={50} width="50%" />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2} }}>
                                <Skeleton animation="wave" height={20} width="20%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={30} width="100%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={20} width="20%" />
                            </TableCell>
                            <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                <Skeleton animation="wave" height={50} width="50%" />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Button fullWidth variant="contained" sx={{mb: 2, height: '50px', mt: 2}}
                    color="primary">
                <Skeleton animation="wave" height={60} width="20%" />
            </Button>
        </Box>
    );
};

export default SkeletonSite;