import React from 'react'
import {TextField} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

export const SearchBar = ({ items, setSearchResults }) => {

    const handleSearchChange = (e) => {
        if (!e.target.value) return setSearchResults(items)

        const resultsArray = items.filter(items =>  items.ItemName.toLowerCase().includes(e.target.value.toLowerCase()))

        setSearchResults(resultsArray)
    }

    return (
        <>
            <TextField variant="filled" onChange={handleSearchChange}
                       sx={{ fontSize: {xs: 13, md: 15}, m: 1, mr: 0, ml: 0 }}
                       InputProps={{
                           endAdornment: <InputAdornment position="end"><ManageSearchIcon /></InputAdornment>,
                       }} label="Rechercher des articles..." />
        </>
    )
}

export default SearchBar