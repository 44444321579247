import React, {useState} from 'react';
import {Box, Button, Tooltip, Skeleton, Typography, Modal, TextField} from "@mui/material";
import {
    decreaseItemQuantity,
    ereaseOneItem,
    increaseItemQuantity,
    setId,
    validCart
} from "../../../reducers/cart/cartSlice";
import {apiPost} from "../../../authentication/OAuth2Utils";
import {clone} from "ramda";
import CartRow from "../CartRow";

const Cart = ({itemsInCart, dispatch, cartWarehouseCode, cartStatus, warehousesList, cart}) => {

    const [isSaving, setIsSaving] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [dynamicItemCode, setDynamicItemCode] = useState(null)

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '2px solid #707070',
        boxShadow: 24,
        p: 4,
        borderRadius: '15px'
    }

    const postCloned = (clonedCart) => {
        apiPost('/api/savecart', clonedCart).then(data => {
            if(data.status === 'valid'){
                dispatch(setId(data.cart))
                setIsSaving(false)
            }
        })
    }

    const handleSavingCart = (param, itemIndex) => {
        if(cartStatus !== 'valid') {
            setIsSaving(true)
            let clonedCart = clone(cart)
            if(param === 'add') {
                clonedCart.itemsInCart[itemIndex].itemQuantity += 1
                postCloned(clonedCart)
                dispatch(increaseItemQuantity(itemsInCart[itemIndex].itemCode))
            } else if (param === 'rmv') {
                if(clonedCart.itemsInCart !== null) {
                    // vérifier la quantité si non ouvrir la modale
                    if(clonedCart.itemsInCart[itemIndex].itemQuantity > 1) {
                        clonedCart.itemsInCart[itemIndex].itemQuantity -= 1
                        postCloned(clonedCart)
                        dispatch(decreaseItemQuantity(itemsInCart[itemIndex].itemCode))
                    } else if (clonedCart.itemsInCart[itemIndex].itemQuantity === 1) {
                        // ouvrir la modale et demander la suppression
                        setDynamicItemCode(itemIndex)
                        setOpenModal(true)
                    }
                }
            }
        }
    }

    return (
        <>
            <Modal open={openModal} onClose={() => {
                setOpenModal(false)
                setIsSaving(false)
            }}>
                <Box sx={styleModal}>
                    <Typography variant="h6" sx={{ textAlign: 'center' }}>
                        Cela va supprimer l'article de votre panier. Souhaitez vous continuer ?
                    </Typography>
                    <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'space-evenly' }}>
                        <Button variant="outlined" color="inherit" size="large"
                                onClick={() => {
                                    setOpenModal(false)
                                    setIsSaving(false)
                                }}>
                            Non
                        </Button>
                        <Button variant="outlined" size="large"
                                color="error"
                                onClick={() => {
                                    let clonedCart = clone(cart)
                                    delete clonedCart.itemsInCart[dynamicItemCode]
                                    dispatch(ereaseOneItem(itemsInCart[dynamicItemCode].itemCode))
                                    postCloned(clonedCart)
                                    setOpenModal(false)
                                }}>
                            Oui
                        </Button>
                    </Box>
                </Box>
            </Modal>
            {itemsInCart != null &&
                Object.keys(itemsInCart).map((itemKey, index) => {
                    let clonedCart = clone(cart)
                    return (
                        <CartRow itemsInCart={itemsInCart} itemKey={itemKey} warehousesList={warehousesList} key={index}
                                 cartWarehouseCode={cartWarehouseCode} handleSavingCart={handleSavingCart} isSaving={isSaving}
                                 dispatch={dispatch} postCloned={postCloned} cart={clonedCart} setOpenModal={setOpenModal}
                                 setDynamicItemCode={setDynamicItemCode} setIsSaving={setIsSaving}/>
                    )
                })
            }
        </>
    )

};

export default Cart;