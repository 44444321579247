import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {apiPost} from "../../../authentication/OAuth2Utils";
import {
    replaceProducts,
    replaceWarehouses,
    replaceWarehousesDistance,
    replaceWarehousesList
} from "../../../reducers/product/productSlice";
import {replaceDataForCart, setWarehouseEmail} from "../../../reducers/cart/cartSlice";
import {usePosition} from "../../../hooks/Position";

const Index = ({dispatch, items, warehousesSearched}) => {

    // faut faire ici l'use effect pour appeler les données du site a chaque fois
    // faire un switch en fonction de la location
    const [readyToCall, setReadyToCall] = useState(false)
    const location = useLocation()
    const userPosition = usePosition()
    const navigate = useNavigate()
    const checkIfGeolocIsOn = () => {
        navigator.geolocation.getCurrentPosition(function () {
            setReadyToCall(true)
        }, function (error) {
            navigate('/geoloc')
        })
    }

    // navigator.geolocation.getCurrentPosition(function () {
    //
    // }, function (error) {
    //     if(error.code === error.PERMISSION_DENIED) {
    //         console.log('il a pas accépté')
    //         navigate('/geoloc')
    //     }
    // })

    useEffect(() => {
        checkIfGeolocIsOn()
        if(items === null) {
            if(readyToCall) {
                apiPost('/api/get-items', userPosition).then(data => {
                    if(data.valid === true) {
                        dispatch(replaceWarehousesDistance(data.warehouses[1]))
                        dispatch(replaceProducts(data.liste_article))
                        dispatch(replaceDataForCart(data.cart))
                        dispatch(replaceWarehousesList(data.warehouses[0][0]))
                        if(data.cart !== null){
                            dispatch(replaceWarehouses(data.cart.warehouseSearch))
                            dispatch(setWarehouseEmail(data.cart.warehouseEmail))
                            if(location.pathname === '/magasin') {
                                navigate('/shopping')
                            }
                        } else if(location.pathname !== '/magasin') {
                            navigate('/magasin')
                        }
                    } else {
                        console.error("erreur")
                    }
                })
            }

            } else {
            // gérer la navigation en fonction de l'état des données
            if(warehousesSearched != null && location.pathname === '/magasin') {
                navigate('/shopping')
            }

            if(warehousesSearched === null & location.pathname === '/shopping') {
                navigate('/magasin')
            }

        }



    }, [readyToCall])

    return (
        <>

        </>
    );
};

export default Index;