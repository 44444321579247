import React, {useState} from 'react';
import Cart from "../../components/shop/Cart";
import {Box, CssBaseline, Typography, Container, Button, Modal, Skeleton, Alert, CircularProgress} from "@mui/material";
import BottomNav from "../../components/shop/BottomNav";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {isEmpty} from "ramda";
import {ereaseCart, setId, validCart} from "../../reducers/cart/cartSlice";
import {apiPost} from "../../authentication/OAuth2Utils";
import {ereaseWarehouses} from "../../reducers/product/productSlice";
import ShoppingCartSkeleton from "../../components/shop/ShoppingCartSkeleton";
import HeaderShop from "../../components/shop/HeaderShop";
import Index from "../../components/shop/CallAndNav";

const ShoppingCart = ({itemsInCart = null, dispatch, cartWarehouseCode=null, warehousesSearched = null,
                          cartStatus = null, cart = null, items = null, warehousesList = null}) => {

    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [openSecondModal, setOpenSecondModal] = useState(false)
    const [validSuccesful, setValidSuccesful] = useState(false)
    const [validBtnLoading, setValidLoading] = useState(false)
    const [deleteBtnLoading, setDeleteBtnLoading] = useState(false)

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '2px solid #707070',
        boxShadow: 24,
        p: 4,
        borderRadius: '15px'
    }

    //gérer la suppression dans la db et dans le state du panier
    const handleDeletingCart = () => {
        if(cart.id != null) {
            setDeleteBtnLoading(true)
            let toSend = cart.id
            apiPost('/api/deletecart', toSend).then(data => {
                if(data.status === 'valid') {
                    dispatch(ereaseCart())
                    dispatch(ereaseWarehouses())
                    setDeleteBtnLoading(false)
                    setOpenSecondModal(false)
                }
            })
        } else {
            dispatch(ereaseCart())
            dispatch(ereaseWarehouses())
            setOpenSecondModal(false)
        }
    }

    //gérer la validation du panier faut changer de route
    const handleValidatingCart = () => {
        setValidLoading(true)
        let toSend = {cart, status: 'valid'}
        apiPost('/api/validatecart', toSend).then(data => {
            if (data.status === 'valid') {
                    dispatch(setId(data.cart))
                    setValidSuccesful(true)
                    setValidLoading(false)
                    navigate('/confirm')
                }
        })

    }

    return (
        <Container component="main">
            <Index dispatch={dispatch} items={items} warehousesSearched={warehousesSearched}/>
            <HeaderShop isAuth={true} cart={cart} />
            <CssBaseline />
            <Box
                sx={{
                    marginTop: {xs: 16, sm: 17, md: 18},
                    mb: 9,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }} >
                <Box noValidate
                     sx={{ mt: 1, boxShadow: 5, p: 1, width: {xs: '100%', sm: '100%', md: '80%', lg: '75%', xl: '75%'},
                         borderRadius: '10px', mb: 3,
                         minHeight: '200px'}}>
                    <Modal open={open} onClose={() => {setOpen(false)}}>
                        <Box sx={styleModal}>
                            <Typography variant="h6" sx={{ textAlign: 'center' }}>
                                Êtes-vous sûr de vouloir passer commande ?
                            </Typography>
                            <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'space-evenly' }}>
                                <Button variant="outlined" color="inherit" size="large"
                                        onClick={() => {setOpen(false)}}>
                                    Non
                                </Button>
                                <Button variant="outlined" size="large"
                                        onClick={() => {
                                            dispatch(validCart())
                                            handleValidatingCart(cart)
                                            setOpen(false)
                                        }}>
                                    Oui
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal open={openSecondModal} onClose={() => {setOpenSecondModal(false)}}>
                        <Box sx={styleModal}>
                            <Typography variant="h6" sx={{ textAlign: 'center' }}>
                                Êtes-vous sûr de vouloir vider votre panier ?
                            </Typography>
                            <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'space-evenly' }}>
                                <Button variant="outlined" color="inherit" size="large"
                                        onClick={() => {setOpenSecondModal(false)}}>
                                    Non
                                </Button>
                                <Button variant="outlined" size="large"
                                        color="error"
                                        onClick={() => {
                                            handleDeletingCart()
                                        }}>
                                    Oui
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <>
                                <Typography variant="h5" sx={{margin: 0}}>Panier</Typography>
                            </>
                        </Box>
                    </Box>
                    { items !== null ?
                        <>
                            {validSuccesful && <Alert severity="success" sx={{ mb: 2 }}>Panier validé !</Alert>}
                            <Box sx={{minHeight: 250, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', mb: {xs: 2, sm:0} }}>
                                <Cart itemsInCart={itemsInCart} dispatch={dispatch} cartWarehouseCode={cartWarehouseCode}
                                      cartStatus={cartStatus} warehousesList={warehousesList} cart={cart}/>
                            </Box>
                            {validBtnLoading ?
                                    <Button variant="contained" fullWidth><CircularProgress size={20} /></Button>
                                :
                                <>
                                    {itemsInCart !== null && <Button fullWidth variant="contained" onClick={() => {setOpen(true)}}>Passer commande</Button>}
                                </>
                            }
                            {!(isEmpty(itemsInCart)) &&
                                deleteBtnLoading ?
                                <Button fullWidth variant="outlined" sx={{marginTop: '10px'}} color="error"><CircularProgress size={20} /></Button>
                            :
                                itemsInCart !== null && <Button fullWidth variant="outlined" sx={{marginTop: '10px'}} color="error" onClick={() => setOpenSecondModal(true)}>Vider le panier</Button>
                            }


                            <Button fullWidth variant="contained" onClick={() => {navigate('/shopping')}} sx={{ mb: 2, mt: 2, fontSize: {xs: 13, sm: 14} }} color="inherit">Revenir à la liste des articles</Button>
                        </>
                        :
                        <ShoppingCartSkeleton />
                    }
                </Box>
            </Box>
            <BottomNav />
        </Container>
    );
};

export default connect(
    state => ({
        items : state.productsReducer.items,
        itemsInCart: state.cartReducer.itemsInCart,
        cartWarehouseCode: state.cartReducer.warehouseCode,
        cartStatus: state.cartReducer.status,
        cart: state.cartReducer,
        warehousesSearched: state.productsReducer.warehouses,
        warehousesList: state.productsReducer.warehousesList
    })
)(ShoppingCart)