import {Alert, Box, Button, Skeleton, TextField, Typography} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React from "react";

const ConfirmSkeleton = () => {
    return (
        <>
            <Box noValidate
                 sx={{ mt: 1, boxShadow: 5, p: 1, width: {xs: '100%', sm: '100%', md: '80%', lg: '75%', xl: '75%'},
                     borderRadius: '10px', mb: 3,
                     minHeight: '420px'}}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                        <>
                            <Typography variant="h5" sx={{margin: 0, fontSize: {xs: 20, sm: 22, md: 24}, mr: {xs: 3, sm: 0} }}><Skeleton width={200} height={30}/></Typography>
                            <Box sx={{ position: 'relative', left: {xs: 0,sm: 130,md: 150, lg: 170, xl: 170}, width: '34px', height: '34px', borderRadius: '17px', display: 'flex',
                                justifyContent: 'center', alignItems: 'center', cursor: 'pointer', boxShadow: 5}}>
                                <Skeleton variant="circular" width={30} height={30} />
                            </Box>
                        </>
                    </Box>
                </Box>
                <Box sx={{minHeight: 200, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: {xs: 16, md: 18}, margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'} }}>
                        <Skeleton width={310} height={20} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: {xs: 16, md: 18}, margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'} }}>
                        <Skeleton width={300} height={20} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: {xs: 16, md: 18}, margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'} }}>
                        <Skeleton width={280} height={20} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: {xs: 16, md: 18}, margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'} }}>
                        <Skeleton width={260} height={20} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: {xs: 16, md: 18}, margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'} }}>
                        <Skeleton width={300} height={20} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box  sx={{margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'}, display:'flex', justifyContent: 'center'}}>
                        <Skeleton width="100%" height={40} />
                    </Box>
                    <Button variant="contained" sx={{margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 0 40px'}, fontSize: {xs: 14, md: 18} }} ><Skeleton width={80} height={20} /></Button>
                    <Button variant="outlined" color="error" sx={{margin: {xs: '10px 15px 0 15px', sm: '20px 30px 0 30px', md: '20px 40px 20px 40px'}}}><Skeleton width={80} height={20} /></Button>
                </Box>
            </Box>
        </>
    )
}

export default ConfirmSkeleton