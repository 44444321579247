import React from 'react';
import {AppBar, Box, Toolbar} from "@mui/material";
import Logo from "../../../styles/assests/air8_logo.jpg";
import OutputRoundedIcon from '@mui/icons-material/OutputRounded';
import StorefrontIcon from '@mui/icons-material/Storefront';
import {logout} from "../../../authentication/Login";
import {useNavigate} from "react-router-dom";

const Header = ({isAuth}) => {

    const navigate = useNavigate()

    return (
        <AppBar>
            <Toolbar sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'white',
                p: 2
            }}>
                <div onClick={() => {navigate('/magasin')}} style={{cursor: "pointer"}}>
                    <StorefrontIcon color="primary"
                                       fontSize="large"
                                       sx={{
                                           position: 'relative',
                                           left: {
                                               xs: -30,
                                               sm: -30,
                                               md: -50,
                                               lg: -50,
                                               xl: -50
                                           }
                                       }}/>
                </div>
                <Box
                component="img"
                sx={{
                    width: {
                        xs: 150,
                        sm: 180,
                        md: 200,
                        lg: 200,
                        xl: 200
                    },
                }}
                alt="air8 logo"
                src={Logo}
                />
                {isAuth &&
                    <>
                        <div onClick={logout} style={{cursor: "pointer"}}>
                            <OutputRoundedIcon color="primary"
                                               fontSize="large"
                                               sx={{
                                                   position: 'relative',
                                                   right: {
                                                       xs: -30,
                                                       sm: -30,
                                                       md: -50,
                                                       lg: -50,
                                                       xl: -50
                                                   }
                                               }}/>
                        </div>
                    </>
                }
            </Toolbar>
        </AppBar>
    );
};

export default Header;