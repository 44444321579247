import React, {useEffect, useState} from 'react';
import {weekAfter, weekBefore, getDayOfWeek, dateToFrenchFormat} from "../../Utils";
import {Link, Navigate, useNavigate, useParams} from "react-router-dom";
import {apiGet} from "../../authentication/OAuth2Utils";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    CssBaseline,
    Grid,
    Table, TableCell,
    TableContainer,
    TableHead,
    Paper, TableBody, TableRow
} from "@mui/material";
import SkeletonForm from "../../components/timeSheet/SkeletonForm";
import Header from "../../components/timeSheet/Header";
import {Typography} from "@mui/material";
import {useViewport} from "../../hooks/ViewportContext";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SkeletonSite from "../../components/timeSheet/SkeletonList";

const List = () => {

    let { year } = useParams()
    let { week } = useParams()

    let [yearBfr, weekBfr] = weekBefore(year, week);
    let [yearAft, weekAft] = weekAfter(year, week);

    const [datas, setDatas] = useState(null)
    const [loading, setLoading] = useState(true)
    const [valid, setValid] = useState(false)

    useEffect(() => {
        setLoading(true)
        apiGet('/api/list/' + year + '/' + week).then(data => {
            if (data.status === 'success'){
                if(data.response.length !== 0) {
                    setDatas(data.response.list)
                    setLoading(false)
                    setValid(data.response.valid)
                } else {
                    setLoading(false)
                    setDatas([])
                }
            } else {
                console.log('pb')
            }
        })
    }, [week, weekBfr, weekAft])

    const navigate = useNavigate()
    const {width, height, size} = useViewport()

    return (
        <Container component="main">
            <Header isAuth={true} />
            <CssBaseline />
                <Box
                    sx={{
                        marginTop: 18,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }} >
                    <Box noValidate
                         sx={{ mt: 1, boxShadow: 5, p: 1, width: {
                                 xs: '100%',
                                 sm: '100%',
                                 md: '80%',
                                 lg: '75%',
                                 xl: '75%'
                             }, borderRadius: '10px', mb: 3,
                             minHeight: '500px'}}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    navigate(`/list/${yearBfr}/${weekBfr}`)}}>
                                {width < 600 ?
                                        <KeyboardArrowLeftIcon />
                                    :
                                        'Précédente'
                                }
                            </Button>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                {width < 600 ?
                                    <>
                                        <Typography variant="h6" sx={{ margin: 0, fontSize: {xs: 16, sm: 18} }}>Semaine {week} ({year})</Typography>
                                        {valid ?
                                        <Typography variant="h7" sx={{ margin: 0, fontSize: {xs: 15, sm: 17} }}>Validée</Typography>
                                        :
                                        <Typography variant="h7" sx={{ margin: 0, fontSize: {xs: 15, sm: 17} }}>Non validée</Typography>
                                        }
                                    </>
                                :
                                    <>
                                        <Typography variant="h5" sx={{ margin: 0 }}>Semaine {week} ({year})</Typography>
                                        {valid ?
                                        <Typography variant="h6" sx={{ margin: 0}}>Validée</Typography>
                                        :
                                        <Typography variant="h6" sx={{ margin: 0 }}>Non validée</Typography>
                                        }
                                    </>
                                }
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    navigate(`/list/${yearAft}/${weekAft}`)}}>
                                {width < 600 ?
                                    <KeyboardArrowRightIcon />
                                    :
                                    'Suivante'
                                }
                            </Button>
                        </Box>
                        {loading || datas === null ?
                                    <SkeletonSite />
                                    :
                                    <Box sx={{minHeight: 350, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                                            <Table sx={{ minWidth: {
                                                    xs: 250,
                                                    sm: 350,
                                                    md: 550,
                                                    lg: 650,
                                                    xl: 650}
                                            }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>Chantier</TableCell>
                                                        <TableCell>Heures</TableCell>
                                                        {width < 600 ?
                                                            null
                                                            :
                                                            <TableCell>Actions</TableCell>
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {datas.map((row, index) => {
                                                        return (
                                                            <TableRow key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                      onClick={() => {
                                                                          navigate(`/edit/${row.date}/${row.order}`)
                                                                      }}>
                                                                <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2} }}>
                                                                    {getDayOfWeek(row.date).substr(0, 3) + '. ' + dateToFrenchFormat(row.date)}
                                                                </TableCell>
                                                                <TableCell sx={{ fontSize: {
                                                                        xs: 12,
                                                                        sm: 13,
                                                                        md: 15,
                                                                        lg: 16,
                                                                        xl: 18
                                                                    }, p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                                                    {row.customer}
                                                                </TableCell>
                                                                <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                                                    {row.hours}
                                                                </TableCell>
                                                                {width < 600 ?
                                                                    null
                                                                    :
                                                                    <TableCell sx={{ p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}}>
                                                                        <Button variant="outlined" color="primary" onClick={() => {
                                                                            navigate(`/edit/${row.date}/${row.order}`)
                                                                        }}>
                                                                            Éditer
                                                                        </Button>
                                                                    </TableCell>
                                                                }
                                                            </TableRow>
                                                        )
                                                    })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Button fullWidth variant="contained" sx={{mb: 2, height: '50px', mt: 2}}
                                        color="primary" component={Link} to="/">
                                        Nouvelle saisie
                                        </Button>
                                </Box>
                            }
                    </Box>
                </Box>
        </Container>
    )
}

export default List;