import {Box, Button, Skeleton, Typography} from "@mui/material";
import React from "react";

const SliderSkeleton = () => {


    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{margin: 0}}><Skeleton variant="rectangular" width={250} height={30} /></Typography>
                </Box>
            </Box>
            <Box sx={{minHeight: 250, display: 'flex', flexDirection: 'column', marginBottom: '20px', width: {xs: '100%', sm: '80%'}}}>
                <Box sx={{display: 'flex', widht: "100%", justifyContent: 'center', mb: 1}}>
                    <Skeleton width={160} height={20} />
                </Box>
                <Skeleton width="100%" height={30} />
                <Box sx={{display: 'flex', widht: "100%", justifyContent: 'center'}}><Skeleton width={200} height={20}/></Box>
            </Box>
            <Button variant="contained" fullWidth><Skeleton width={220} height={30}/></Button>
        </>
    )
}

export default SliderSkeleton