import {Box, Button, Skeleton, Typography} from "@mui/material";
import React from "react";

const editCartItemSkeleton = () => {
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{margin: 0, textAlign: 'center'}}><Skeleton width={170} height={30} /></Typography>
                </Box>
            </Box>
            <Box sx={{minHeight: 200, display: 'flex', flexDirection: 'column', marginBottom: '30px'}}>
                <Box sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: {xs: 'center'},
                    marginBottom: '25px', flexDirection: {xs: 'column', sm: 'row'}}}>
                    <Typography variant="string" sx={{ mb: {xs: '20px', sm: 0} }}><Skeleton width={140} height={20} /></Typography>
                    <Typography variant="string"><Skeleton width={140} height={20} /></Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', mb: 1}}>
                    <Typography variant="string"><Skeleton width={100} height={20} /></Typography>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', marginRight: 'auto', marginLeft: 'auto', marginTop: '10px'}}>
                        <>
                            <Button variant="outlined" color="error">
                                <Skeleton width={20} height={20} />
                            </Button>
                            <Box sx={{paddingRight: '20px', paddingLeft: '20px'}}>
                                <Skeleton width={50} height={30} />
                            </Box>
                            <Button variant="outlined" color="success">
                                <Skeleton width={20} height={20} />
                            </Button>
                        </>
                </Box>
            </Box>
            <Button variant="outlined" fullWidth>
                <Skeleton width={90} height={30} />
            </Button>
        </>
    )
}

export  default  editCartItemSkeleton