import {Box, Button, Skeleton, TextField, Tooltip} from "@mui/material";
import React, {useEffect, useState} from "react";
import {clone} from "ramda";
import {setItemQuantity, setWarehouseSearch} from "../../../reducers/cart/cartSlice";
import InputAdornment from "@mui/material/InputAdornment";

const CartRow = ({itemsInCart, itemKey, warehousesList,
                     cartWarehouseCode, isSaving, setIsSaving, handleSavingCart,
                     dispatch, postCloned, cart, setOpenModal, setDynamicItemCode}) => {

    const unit = itemsInCart[itemKey].salesUnit != null ? itemsInCart[itemKey].salesUnit : 'u'
    const [inputValue, setInputValue] = useState(itemsInCart[itemKey].itemQuantity + unit)

    const handleInputRegex = (e) => {
        const regex = /^[0-9\b]+$/
        if (e.target.value === "" || regex.test(e.target.value)) {
            if(e.target.value === "") {
                setInputValue(e.target.value)
            } else {
                setInputValue(parseInt(e.target.value, 10))
            }
        }
    }
    useEffect(() => {
        setInputValue(itemsInCart[itemKey].itemQuantity + unit)
    }, [isSaving])

    const handeInputValueChange = () => {
        let clonedCart = cart
        setIsSaving(true)
        if(inputValue > 0) {
            if(clonedCart.itemsInCart !== null) {
                if(clonedCart.itemsInCart[itemKey]) {
                    // la on change juste la quantité puis on post
                    clonedCart.itemsInCart[itemKey].itemQuantity = inputValue
                    postCloned(clonedCart)
                    dispatch(setItemQuantity(clonedCart.itemsInCart[itemKey]))
                }
            }
        } else if (inputValue === 0) {
            setOpenModal(true)
            setDynamicItemCode(itemKey)
        }
    }

    return (
        <Box sx={{
            display: 'flex', justifyContent: 'space-between', padding: 2, border: 1, flexDirection: {xs: 'column', sm: 'row'},
            borderColor: 'grey.500', borderRadius: 1, minWidth: {xs: 250, sm: 350, md: 550, lg: 650, xl: 650}, mb: {xs: 2, sm: 1}}}>
            <Tooltip title={itemsInCart[itemKey].itemName} placement="top-start">
                <Box sx={{maxWidth: '350px', textOverflow: 'ellipsis', whiteSpace: {xs: 'normal', md: 'nowrap'}, overflow: 'hidden',
                    width: {xs:'100%', sm: '200px', md: '300px', lg: '349px'}, mb: {xs: 1, sm: 0},
                    fontSize: {xs: 14, sm: 15, md: 16, lg: 16}, display: 'flex', justifyContent: {xs: 'center', sm: 'flex-start'} }}>
                    {itemsInCart[itemKey].itemName}</Box>
            </Tooltip>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {isSaving ?
                    <>
                        <Button variant="outlined" color="error" onClick={() => {}} sx={{mr: 1}}>
                            <Skeleton variant="rectangular" width={32} height={25} />
                        </Button>
                        <Box>
                            <TextField hiddenLabel variant="standard" size="small" sx={{ width: '45px'}}
                                       inputProps={{ style: {textAlign: 'center'}, inputMode: 'numeric', pattern: '[0-9]*' }} value={itemsInCart[itemKey].itemQuantity + (itemsInCart[itemKey].salesUnit != null ? itemsInCart[itemKey].salesUnit : 'u')}
                            />
                        </Box>
                        <Button variant="outlined" color="success" onClick={() => {}} sx={{ml: 1}}>
                            <Skeleton variant="rectangular" width={32} height={25} />
                        </Button>
                    </>
                    :
                    <>
                        <Button variant="outlined" color="error" onClick={() => {handleSavingCart('rmv', itemKey)}} sx={{mr: 1}}>
                            -
                        </Button>
                        <Box>
                            <TextField hiddenLabel variant="standard" size="small" sx={{ width: '45px'}}
                                       inputProps={{ style: {textAlign: 'center' } }} onChange={(e) => {handleInputRegex(e)}}
                                       onBlur={() => {handeInputValueChange()}} value={inputValue}
                            />
                        </Box>
                        <Button variant="outlined" color="success" onClick={() => {handleSavingCart('add', itemKey)}} sx={{ml: 1}}>
                            +
                        </Button>
                    </>
                }
            </Box>
        </Box>
    )

}

export default  CartRow